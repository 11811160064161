import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { GoCalendar } from "react-icons/go";
import { AiFillStar } from "react-icons/ai";
import { BsClock, BsArrowRight } from "react-icons/bs";
import { AiOutlineCaretLeft } from "react-icons/ai";
import { FaHeart, FaRegClock, FaRegHeart } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import StadiumIcon from "../../assets/images/mobile-list-stadium-icon.png";
import information from "../../assets/images/information.png";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import "./mobileFixturesListItem.scss";
import { colorSetter } from "../../utils";
import { trimString } from "../../helpers/trimString";
import {
  selectMarker,
  selectMarkerSelected,
} from "../../redux/map/mapSelectors";
import { selectFixturesLeagues } from "../../redux/fixtures/fixturesSelectors";
import {
  selectLiveMatchesDate,
  selectShowOnlyLive,
} from "../../redux/live/liveSelectors";
import {
  selectFavMatches,
  selectShowOnlyFav,
} from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import {
  fetchFavMatch,
  removeFavMatch,
} from "../../redux/favoriteMatches/favoriteMatchesActions";
import { addToWishlist } from "../../helpers/getWishlist";
import { showSuccess } from "../ToastMessage";
import { fetchWishlistRequest } from "../../redux/auth/authActions";
import Mixpanel from "../../utils/mixPanel";
import { FaArrowRight } from "react-icons/fa";
import UnlockFeatureModal from "../UnlockFeatureModal";
import { getCookie } from "../../utils/cookies";
const platform = typeof window !== "undefined" ? "web" : "mobile";
const MobileFixturesListItem = ({
  item,
  width,
  callback,
  marker,
  markerSelected,
  leagues,
  index,
  normalizedLeagues,
  type,
  liveDate,
  favMatchesIds,
  fetchFavMatch,
  removeFavMatch,
  id,
  selectedMobileFixture,
  event_timestamp,
  isWishlistFrom,
  fetchWishlistRequest,
}) => {
  const { wishlist } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const [unlockFeatureModal, setUnlockFeatureModal] = useState(false);
  const leagueData = useSelector((state) => state.leagues);

  const isSearchOptions = () => {
    if (leagueData?.selectedTeams?.length > 0) {
      return true;
    }

    if (leagueData?.selectedStadiums?.length > 0) {
      return true;
    }

    if (leagueData?.selectedLeagues?.length > 0) {
      return true;
    }

    return false;
  };
  const score =
    item.statusShort !== "NS" && item.statusShort !== "TBD"
      ? "events" in item
        ? `${item?.goalsHomeTeam} : ${item?.goalsAwayTeam}`
        : (item?.score && item.score?.fulltime) || item.score?.halftime
      : "- : -";

  const [addWishlist, setAddWishlist] = useState(false);
  const addOrRemoveWishlist = async (item) => {
    Mixpanel.track(`Pressed wishlist`, {
      match: `${item?.home_team?.name} vs ${item?.away_team?.name}`,
      platform: platform,
      application_type:
        platform === "web" ? "web app" : "mobile app",
      status: `Fixture ${addWishlist?"removed":"added"} to wishlist`,
    });
    const authToken = getCookie("token");
    if (!authToken) {
      let localWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

      const itemExists = localWishlist.find(w => w.id === item.id);

      if (!itemExists) {
        localWishlist.push(item);
      } 

      localStorage.setItem("wishlist", JSON.stringify(localWishlist));
      setUnlockFeatureModal(true);
      return;
    }
    const response = await addToWishlist(item?.id);
    if (response?.success) {
      showSuccess(response?.data?.message);
      fetchWishlistRequest(user?._id);
    }
  };
  useEffect(() => {
    const check = wishlist[item?.id];
    setAddWishlist(check ? true : false);
  }, [item, wishlist]);
  useEffect(() => {
      if (user) {
        const localWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        
        if (localWishlist.length > 0) {
          localWishlist.forEach(async (wishItem) => {
            const response = await addToWishlist(wishItem.id);
            if(response?.success){
              showSuccess(response?.data?.message);
            }
          });
  
          localStorage.removeItem("wishlist");
          fetchWishlistRequest(user?._id);
        }
      }
  }, [user]);
  return (
    <div
      id={`item-${id}`}
      onClick={callback}
      className={`mobile-fixtures-list-item-container ${
        item &&
        marker &&
        marker?.fixture_id === item?.fixture_id &&
        "selected-fixture-list-item"
      } ${markerSelected && "selected-mobile-fixture-list-item"}`}
      style={{ borderLeft: `3px solid ${colorSetter(item?.distance)}` }}
    >
      <div>
        <div className="mobile-fixture-team-container">
          <div className="mobile-fixture-league">
            <div className="mobile-league-time">
              <FaRegClock />
              {"events" in item
                ? `${item.elapsed}'`
                : type === "home"
                ? moment(item?.event_timestamp * 1000).format("HH:mm")
                : moment(item?.event_timestamp * 1000).format("HH:mm A")}
            </div>
            <div className="mobile-league-name">
              <img src={item.league.logo} alt="league-icon" loading="lazy" />
              <span>{item.league.name}</span>
            </div>
          { !isSearchOptions() ? (
            <div
              className="mobile-league-location"
              style={{ color: colorSetter(item?.distance) }}
            >
             <FaMapMarkerAlt
               size={"12px"}
               color={colorSetter(item?.distance)}
              />
             {typeof item?.distance === "number" && !isNaN(item?.distance)
              ? `${Math.floor(item?.distance)} Km`
              : "N/A"}
            </div>
           ) : (
             <div style={{flex:0.2}}>
               {/* Your alternate content here */}
             </div>
           )
         }

          </div>
          {/* <div className="mobile-fixture-team-data"> */}

          <div className="mobile-fixture-team">
            <div className="mobile-team-name">
              <span>{item?.home_team?.team_name || item?.home_team?.name}</span>
              {/* <img
                alt="home-team"
                src={item?.home_team?.logo}
                onError={(e) => (e.target.src = item?.home_team?.logo)}
                loading="lazy"
              /> */}
            </div>
            <div>
              <div className="vs-txt" style={{display:'flex', alignItems:'center', gap:'5px'}}>
                <img
                  style={{width:'20px', height:'20px'}}
                  alt="home-team"
                  src={item?.home_team?.logo}
                  onError={(e) => (e.target.src = item?.home_team?.logo)}
                  loading="lazy"
                />
                vs
                <img
                  style={{width:'20px', height:'20px'}}
                  alt="away-team"
                  src={item?.away_team?.logo}
                  onError={(e) => (e.target.src = item?.away_team?.logo)}
                  loading="lazy"
                />
              </div>
              {isWishlistFrom && (
                <div className="vs-date-txt">
                  {moment(item?.event_timestamp * 1000).format("DD-MM-YYYY")}
                </div>
              )}
            </div>
            <div className="mobile-team-name">
              {/* <img
                alt="away-team"
                src={item?.away_team?.logo}
                onError={(e) => (e.target.src = item?.away_team?.logo)}
                loading="lazy"
              /> */}
              <span>{item?.away_team?.team_name || item?.away_team?.name}</span>
            </div>
            {/* <img className="mobileinfo-icon" src={information} alt="stadium" /> */}
          </div>
          {/* </div> */}
          <div className="mobile-fixture-heart-section">
            <button
              onClick={() => addOrRemoveWishlist(item)}
              className="mobile-fixture-wishlist"
            >
              {!addWishlist ? <FaRegHeart /> : <FaHeart />}
            </button>
            <div className="date-time-container">
              <FaMapMarkerAlt color="#5256c8" size={"12px"} />
              <p>
                {" "}
                {isWishlistFrom
                  ? item.venue + ", " + item.city + " ," + item?.country
                  : item.venue + ", " + item.city}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*         
        <div className="mfli-rows">        
          <div className="mfli-leagueNameView">
            <span
              className="mfli-leagueName"
              style={{
                backgroundColor: colorSetter(item?.distance)
              }}
            >
              {item?.league?.name
                ? trimString(item?.league?.name, width <= 400 ? 16 : 18)
                : ""}
            </span>
          </div>
          <span
            className="mfli-radius"
            style={{ color: colorSetter(item?.distance) }}
          >
            {typeof item?.distance === "number" && !isNaN(item?.distance)
              ? `${Math.floor(item?.distance)} Km`
              : "N/A"}
          </span>
        </div> */}
      {/* <div className="mfli-teamsRow">

          <div className="mfli-teamsRow-left">
            <div className="mfli-main">
              <span className="mfli-title">
                {trimString(
                  item?.home_team?.team_name || item?.home_team?.name,
                  width <= 400 ? 18 : 20
                )}
              </span>
              <div className="mfli-imgWrapper">
                <img
                  className="mfli-teamLogo"
                  alt="home-team"
                  src={item?.home_team?.logo}
                  onError={(e) => e.target.src = item?.home_team?.logo}
                />
              </div>
            </div>
            {type !== "home" ? (
              <span className="mfli-title">{score}</span>
            ) : (
                <div className="mfli-vs">
                  <span className="mfli-vsText">VS</span>
                </div>
              )}
            <div className="mfli-sub">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "85%"
                }}
              >
                <div className="mfli-imgWrapper">
                  <img
                    className="mfli-teamLogo"
                    alt="away-team"
                    src={item?.away_team?.logo}
                    onError={(e) => e.target.src = item?.away_team?.logo}
                  />
                </div>

                <span className="mfli-subTitle">
                  {"  "}
                  {trimString(
                    item?.away_team?.team_name || item?.away_team?.name,
                    width <= 400 ? 18 : 20
                  )}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "15%",
                  left: 15,
                  justifyContent: "flex-start"
                }}
              >
                <div className="mfli-leagueImgWrapper">
                  {leagues && leagues[item?.league_id] ? (
                    <img
                      className="mfli-leagueLogo"
                      alt="league"
                      src={leagues[item?.league_id]?.logo}
                      onError={(e) => e.target.src = leagues[item?.league_id]?.logo}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="mfli-teamsRow-right">
            <img
              className="info-icon"
              src={information}
              alt="stadium"
              onError={(e) => e.target.src = information}
            />
          </div>
        </div> */}

      {/* <div className="mfli-rows">
          <div className="mfli-date-time-container">
            {type === "home" && (
              <>
                <GoCalendar size={width <= 360 ? 10 : 12} color="#5B5B5B" />
                <span className="mfli-time">
                  {`${moment(item?.event_date).utc().format("DD/MM/YYYY")}`}
                </span>
                <div className="mfli-separator-line" />
              </>
            )}
            <span className="flex-row">
              <BsClock
                color="#5B5B5B"
                size={width <= 360 ? 9 : 10}
                style={{
                  marginRight: width <= 360 ? 1 : 2,
                  marginLeft: type === "home" ? 2 : 0
                }}
              />ccc
              <span className="mfli-time">
                {"events" in item
                  ? `${item.elapsed}'`
                  : type === "home"
                    ? moment(item?.event_timestamp * 1000).format("HH:mm")
                    : moment(item?.event_timestamp*1000).format("HH:mm A")}
              </span>
            </span>
            {type !== "home" && (
              <span className="mfli-statusShort">{item?.statusShort}</span>
            )}
            {type !== "home" &&
              "events" in item &&
              new Date().getDate() === new Date(liveDate).getDate() && (
                <div className="mfli-live-box">
                  <span className="mfli-live-dot"></span>
                  <span>Live</span>
                </div>
              )}
          </div> */}
      {/* <div className="mfli-venueDate">
          {type === "home" ? (
            <span className="mfli-venue">
              {item.venue
                ? trimString(
                    `${item?.venue.split("(")[0]} (${item?.league.country})`,
                    38
                  )
                : "N/A"}
            </span>
          ) : (
            <div
              onClick={(e) => {
                e.stopPropagation();
                favMatchesIds.includes(item?._id)
                  ? removeFavMatch(item?._id)
                  : fetchFavMatch(item?._id);
              }}
              className={`mfli-live-match-star ${
                favMatchesIds.includes(item?._id) && "highlighted"
              }`}
            >
              <AiFillStar color="white" size={15} />
            </div>
          )}
        </div> */}
      {/* <div onClick={callback}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <span className="mfli-moreInfo">
              {`${type === "home" ? "SUPPORTER INFO" : "MATCH DETAILS"}`}
            </span>
            <BsArrowRight size={15} color="#5256C8" />
          </div>
        </div> */}
      {/* </div> */}
      <UnlockFeatureModal
        unlockFeatureModal={unlockFeatureModal}
        setUnlockFeatureModal={setUnlockFeatureModal}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  width: selectWidth,
  marker: selectMarker,
  markerSelected: selectMarkerSelected,
  leagues: selectFixturesLeagues,
  liveDate: selectLiveMatchesDate,
  favMatchesIds: selectFavMatches,
  onlyLiveMatches: selectShowOnlyLive,
  onlyFav: selectShowOnlyFav,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFavMatch: (payload) => dispatch(fetchFavMatch(payload)),
  removeFavMatch: (payload) => dispatch(removeFavMatch(payload)),
  fetchWishlistRequest: (userId) => dispatch(fetchWishlistRequest(userId)),
});

MobileFixturesListItem.propTypes = {
  item: PropTypes.object,
  width: PropTypes.number,
  onlyLiveMatches: PropTypes.bool,
  onlyFav: PropTypes.bool,
  isItFiltered: PropTypes.bool,
  callback: PropTypes.func,
  marker: PropTypes.object,
  markerSelected: PropTypes.bool,
  leagues: PropTypes.object,
  index: PropTypes.number,
  type: PropTypes.string,
  liveDate: PropTypes.string,
  favMatchesIds: PropTypes.array,
  fetchFavMatch: PropTypes.func,
  removeFavMatch: PropTypes.func,
  normalizedLeagues: PropTypes.object,
  id: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFixturesListItem);
