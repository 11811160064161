/* eslint-disable */
//MAIN MODULES
import React, {
	useRef,
	useEffect,
	useState,
	useContext,
	useCallback,
} from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { connect, useSelector } from "react-redux";
import moment from "moment";

//ACTIONS,SELECTORS
import {
	selectFormValuesDates,
	selectorSelectDay,
} from "../../redux/formValues/formValuesSelectors";
import {
	fetchFormValues,
	selectDay,
} from "../../redux/formValues/formValuesActions";
import { resetMapDetails } from "../../redux/map/mapActions";
import { resetFixtureDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { resetSidebarDetails } from "../../redux/sidebar/sidebarActions";
import { fetchSelectedLeague } from "../../redux/leagues/leaguesActions";
import {
	fetchFixturesByLocationStart,
	resetFixtures,
	setFixture,
	setLeagues,
	resetMobileFixture,
} from "../../redux/fixtures/fixturesActions";
import {
	getFilteringValues,
	getSortingvalue,
	selectMatches,
} from "../../redux/fixtures/fixturesSelectors";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
// STYLES
import "./index.scss";
import { Context } from "../../helpers/context/Context";
import { selectFilterModalSelectedLeaguesIds } from "../../redux/filteringValues/filteringValuesSelectors";
import { filterFixtures } from "../../helpers/filterFixtures";
import { fetchUserLocation } from "../../redux/userLocation/userLocationActions";
import Mixpanel from "../../utils/mixPanel";
import { selectInternalMap } from "../../redux/map/mapSelectors";
import { selectisInternalRouting } from "../../redux/sidebar/sidebarSelectors";
const platform = typeof window !== "undefined" ? "web" : "mobile";
const MatchesDates = ({
	fetchFixturesByLocationStart,
	resetFixtureDetails,
	resetMapDetails,
	fetchSelectedLeague,
	fetchFormValues,
	selectDay,
	matches,
	setFixture,
	setLeagues,
	dates,
	currentSelectDay,
	fetchSidebarDetails,
	resetMobileFixture,
	sortingValues,
	filteringValues,
	selectedLeaguesIds,
	isShow,
	fetchMapDetails,
	fetchUserLocation,
	map,
	isInternalRouting,
}) => {
	const screen = useSelector((state) => state.screenDimensions);
	const leagueData = useSelector((state) => state.leagues);
	const isMobileScreen = screen.width <= 390;

	const slickRef = useRef(null);
	const context = useContext(Context);
	// console.log("Context", Context);
	const timeoutRef = useRef(null);
	const settings = {
		infinite: false,
		speed: 500,
		initialSlide: 0,
		slidesToShow: 7,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		swipeToSlide: true,
		touchThreshold: 10,
	};
	const hasSelectedOptions = (league) => {
		return (
			league?.selectedTeams?.length > 0 ||
			league?.selectedStadiums?.length > 0 ||
			league?.selectedLeagues?.length > 0
		);
	};
	const searchOptions = hasSelectedOptions(leagueData);
	const datesList = useCallback(
		(fixtures) => {
			const { isItFiltered } = filteringValues;
			const { insideRadiusFixtures, outsideRadiusFixtures } = fixtures;

			let mainFixturesList = !context.isAllCountries
				? insideRadiusFixtures || []
				: (insideRadiusFixtures || []).concat(outsideRadiusFixtures || []);

			if (isItFiltered) {
				mainFixturesList = filterFixtures(mainFixturesList, selectedLeaguesIds);
			}

			return mainFixturesList.filter((x) => x?.lat && x?.lng && x?.venue);
		},
		[context.isAllCountries, filteringValues, selectedLeaguesIds]
	);

	const handleLeaguesMap = (date) => {
		// Check if matches and matches[date] exist
		if (!matches || !matches[date]) {
			console.warn(`No data found for the date: ${date}`);
			return;
		}

		// Ensure there are inside and outside radius fixtures
		const firstDayFixtures = [
			...(matches[date]?.insideRadiusFixtures || []),
			...(matches[date]?.outsideRadiusFixtures || []),
		];

		// If no fixtures exist, return early
		if (firstDayFixtures.length === 0) {
			console.warn(`No fixtures found for the date: ${date}`);
			return;
		}

		// Create markers array with valid lat and lng
		const markers = firstDayFixtures
			.filter(
				(fixture) =>
					fixture?.venue &&
					fixture?.location &&
					fixture?.location?.lat &&
					fixture?.location?.lng
			)
			.map((fixture) => ({
				lat: fixture.location.lat,
				lng: fixture.location.lng,
			}));

		// If no valid markers found, return early
		if (markers.length === 0) {
			console.warn(`No valid markers found for the date: ${date}`);
			return;
		}

		// Check if Google Maps API is loaded and if the map object exists
		if (
			typeof window.google === "undefined" ||
			typeof window.google.maps === "undefined" ||
			!map
		) {
			console.error("Google Maps API or map is not available.");
			return;
		}

		// Create LatLngBounds and extend with markers
		const bounds = markers.reduce((bounds, marker) => {
			return bounds.extend(
				new window.google.maps.LatLng(marker.lat, marker.lng)
			);
		}, new window.google.maps.LatLngBounds());

		// Ensure map object is valid before calling fitBounds
		setTimeout(() => {
			if (map) {
				if (markers.length === 1) {
					// If there's only one marker, center and set zoom to 12
					const singleMarkerPosition = new window.google.maps.LatLng(
						markers[0].lat,
						markers[0].lng
					);
					map.setCenter(singleMarkerPosition);
					map.setZoom(12);
				} else {
					// If there are multiple markers, fit bounds to show all markers
					map.fitBounds(bounds);
					 google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
						if (map.getZoom() > 6) {
							map.setZoom(7);
						}
					});
				}
			} else {
				console.error("Map object is not available.");
			}
		}, 0);
	};
	// const handleDateClick = useCallback((currentDate) => {
	// 	Mixpanel.track("Pressed on date", {
	// 		Date: currentDate,
	// 		platform: platform,
	// 		application_type: platform === "web" ? "web app" : "mobile app",
	// 	});

	// 	fetchUserLocation({ isFetchingOnDate: true });
	// 	resetMapDetails();
	// 	resetFixtures();
	// 	context.setIsFirstTime(true);
	// 	fetchFormValues(currentDate);
	// 	setFixture(matches[currentDate] || null);
	// 	debugger;
	// 	setLeagues(matches[currentDate]?.leagues || null);
	// 	if (!isInternalRouting) {
	// 		resetFixtureDetails();
	// 		fetchSidebarDetails({ areFixturesDetailsFetched: false });
	// 	}
	// 	resetMobileFixture();
	// 	if (searchOptions) {
	// 		handleLeaguesMap(currentDate);
	// 	}
	// 	fetchMapDetails({ markerSelectedOnHover: null });
	// 	context.setMobileDateChange(!context.mobileDateChange);

	// 	// Clear any existing timeout
	// 	if (timeoutRef.current) {
	// 		clearTimeout(timeoutRef.current);
	// 	}

	// 	// Set new timeout
	// 	timeoutRef.current = setTimeout(() => {
	// 		fetchUserLocation({ isFetchingOnDate: false });
	// 	}, 1000);
	// }, []);

	const handleDateClick = useCallback((currentDate) => {
		Mixpanel.track("Pressed on date", {
			Date: currentDate,
			platform: platform,
			application_type: platform === "web" ? "web app" : "mobile app",
		});

  const findNextDateWithMatches = (currentDate) => {
    const currentIndex = dates.findIndex((date) => date === currentDate);
    // Look for the next date with matches after the current date
    for (let i = currentIndex; i < dates.length; i++) {
      const matchesForDate = matches[dates[i]];
      const fixturesList = datesList(matchesForDate || {});
      if (fixturesList.length > 0) {
        return dates[i]; // Return the next date with matches
      }
    }
    return null; // No next date with matches found
  };

  // Check if the current date has matches
  const currentFixtures = datesList(matches[currentDate] || {});
  const selectedDate = currentFixtures.length > 0 ? currentDate : findNextDateWithMatches(currentDate);

  if (selectedDate) {
    setFixture(matches[selectedDate] || null); // Set matches for the selected date
    setLeagues(matches[selectedDate]?.leagues || null); // Set leagues for the selected date
  } else {
    console.warn("No matches found for any date.");
  }

  // Reset and fetch necessary data
  fetchUserLocation({ isFetchingOnDate: true });
  resetMapDetails();
  resetFixtures();
  context.setIsFirstTime(true);
  fetchFormValues(selectedDate || currentDate);

  if (!isInternalRouting) {
    resetFixtureDetails();
    fetchSidebarDetails({ areFixturesDetailsFetched: false });
  }
  resetMobileFixture();
  if (searchOptions) {
    handleLeaguesMap(selectedDate || currentDate);
  }
  fetchMapDetails({ markerSelectedOnHover: null });
  context.setMobileDateChange(!context.mobileDateChange);

  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
  }

//   timeoutRef.current = setTimeout(() => {
    fetchUserLocation({ isFetchingOnDate: false });
	fetchMapDetails({})
//   }, 2700);
    }, [dates, matches, datesList]);

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	const findFirstDateWithMatches = () => {
		const dateWithMatches = dates.find((dd) => {
			const mainFixturesList = datesList(matches[dd] || {});
			return mainFixturesList.length > 0;
		});

		if (dateWithMatches) return dateWithMatches;

		const today = moment().format("YYYY-MM-DD");
		return dates.includes(today) ? today : dates[0];
	};

	const [initialDate, setInitialDate] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(0); // Store index of the selected date

	// useEffect(() => {
	//   if (currentSelectDay) {
	//     const index = dates.findIndex((date) => date === currentSelectDay);
	//     console.log("common firstDateWithMatches use", currentSelectDay, index);
	//     setSelectedIndex(index);
	//   }
	// }, [currentSelectDay, isShow]);

	useEffect(() => {
		if (isMobileScreen) {
			if (!currentSelectDay) {
				const firstDateWithMatches = findFirstDateWithMatches();
				setInitialDate(firstDateWithMatches);
				const index = dates.findIndex((date) => date === firstDateWithMatches);
				// console.log(
				//   "common firstDateWithMatches mobile",
				//   firstDateWithMatches,
				//   index
				// );
				setSelectedIndex(index);
				handledate(firstDateWithMatches, index);
			} else {
				const mainFixturesList = datesList(matches[currentSelectDay] || {});
				if (mainFixturesList.length > 0) {
					const firstDateWithMatches = findFirstDateWithMatches();
					setInitialDate(currentSelectDay || firstDateWithMatches);
					const index = dates.findIndex(
						(date) => date === firstDateWithMatches
					);
					// console.log(
					//   "common firstDateWithMatches mobile ELSe",
					//   firstDateWithMatches,
					//   index
					// );
					if (searchOptions) {
						handleLeaguesMap(firstDateWithMatches);
					}
					setSelectedIndex(index);
				} else {
					const firstDateWithMatches = findFirstDateWithMatches();
					setInitialDate(firstDateWithMatches);
					const index = dates.findIndex(
						(date) => date === firstDateWithMatches
					);
					if (searchOptions) {
						handleLeaguesMap(firstDateWithMatches);
					}
					// console.log(
					//   "common firstDateWithMatches mobile ELSe {}",
					//   firstDateWithMatches,
					//   index
					// );
					setSelectedIndex(index);
					handledate(firstDateWithMatches, index);
				}
			}
		}

		if (!isMobileScreen) {
			const firstDateWithMatches = findFirstDateWithMatches();
			setInitialDate(firstDateWithMatches);
			const index = dates.findIndex((date) => date === firstDateWithMatches);

			// console.log(
			//   "common firstDateWithMatches desktop",
			//   firstDateWithMatches,
			//   index
			// );
			setSelectedIndex(index);
			handledate(firstDateWithMatches, index);
		}
	}, [dates, matches, datesList, isMobileScreen]);
	const handledate = (initialDate, selectedIndex) => {
		if (!isInternalRouting) {
			handleDateClick(initialDate);
			selectDay(initialDate);
		} else {
			selectDay(currentSelectDay);
			handleDateClick(currentSelectDay);
		}

		if (slickRef.current && selectedIndex !== -1) {
			// Scroll to the selected date index
			slickRef.current.slickGoTo(selectedIndex);
		}
	};
	// console.log(dates,'------------------',matches,'---------------------','---------',datesList)
	// useEffect(() => {
	//   console.log(initialDate,'--------------------330-----------',selectedIndex)
	//   if (initialDate) {
	//     console.log(initialDate,'--------------------332-----------',selectedIndex)
	//     if (!isInternalRouting) {
	//       handleDateClick(initialDate);
	//       selectDay(initialDate);
	//     } else {
	//       selectDay(currentSelectDay);
	//       handleDateClick(currentSelectDay);
	//     }

	//     if (slickRef.current && selectedIndex !== -1) {
	//       // Scroll to the selected date index
	//       slickRef.current.slickGoTo(selectedIndex);
	//     }
	//   }
	// }, [initialDate, selectedIndex]);
	// console.log(initialDate,'--------------------345-----------',selectedIndex)
	useEffect(() => {
		if (selectedIndex) {
			// console.log("commmo selectedIndex", selectedIndex);
			if (slickRef.current && selectedIndex !== -1) {
				// Scroll to the selected date index
				// console.log("commmo selectedIndex scroll", selectedIndex);
				slickRef.current.slickGoTo(selectedIndex);
			}
		}
	}, [selectedIndex, isShow]);

	const list = dates.map((dd, index) => {
		const mainFixturesList = datesList(matches[dd] || {});
		const isDisabled = mainFixturesList.length === 0;

		return (
			<div
				className="date-container"
				key={dd}
				onClick={() => {
					if (!isDisabled) {
						selectDay(dd);
						handleDateClick(dd);
						// Scroll to the clicked date
						// if (slickRef.current) slickRef.current.slickGoTo(index);
					}
				}}
			>
				<div
					style={{ whiteSpace: "nowrap" }}
					className={`matches-date ${isDisabled ? "disabled" : ""} ${
						dd === currentSelectDay ? "highlight-date" : ""
					}`}
				>
					{new Date(dd).toDateString() === new Date().toDateString()
						? "Today"
						: moment(dd).format("MMM DD")}
				</div>
				<div className={`date-count ${isDisabled ? "disabled" : ""}`}>
					{mainFixturesList.length}
				</div>
			</div>
		);
	});

	if (!isShow) {
		return <></>;
	} else {
		return (
			<div className="matches-dates-wrapper">
				<Slider
					ref={slickRef}
					{...settings}
				>
					{list}
				</Slider>
			</div>
		);
	}
};

const mapStateToProps = (state) => ({
	dates: selectFormValuesDates(state),
	matches: selectMatches(state),
	currentSelectDay: selectorSelectDay(state),
	sortingValues: getSortingvalue(state),
	filteringValues: getFilteringValues(state),
	selectedLeaguesIds: selectFilterModalSelectedLeaguesIds(state),
	map: selectInternalMap(state),
	isInternalRouting: selectisInternalRouting(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchFixturesByLocationStart: () => dispatch(fetchFixturesByLocationStart()),
	fetchFormValues: (currentDate) => dispatch(fetchFormValues({ currentDate })),
	fetchMapDetails: (data) => dispatch(fetchMapDetails(data)),
	fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
	resetFixtureDetails: () => dispatch(resetFixtureDetails()),
	resetFixtures: () => dispatch(resetFixtures()),
	resetMapDetails: () => dispatch(resetMapDetails()),
	resetSidebarDetails: () => dispatch(resetSidebarDetails()),
	selectDay: (payload) => dispatch(selectDay(payload)),
	setFixture: (payload) => dispatch(setFixture(payload)),
	setLeagues: (payload) => dispatch(setLeagues(payload)),
	fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
	resetMobileFixture: () => dispatch(resetMobileFixture()),
	fetchUserLocation: (data) => dispatch(fetchUserLocation(data)),
});

MatchesDates.propTypes = {
	dates: PropTypes.array,
	fetchFormValues: PropTypes.func,
	fetchMapDetails: PropTypes.func,
	fetchUserLocation: PropTypes.func,
	resetFixtureDetails: PropTypes.func,
	resetFixtures: PropTypes.func,
	resetMapDetails: PropTypes.func,
	resetSidebarDetails: PropTypes.func,
	fetchSelectedLeague: PropTypes.func,
	fetchFixturesByLocationStart: PropTypes.func,
	selectDay: PropTypes.func,
	setFixture: PropTypes.func,
	matches: PropTypes.object,
	isShow: PropTypes.any,
	setLeagues: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchesDates);
