import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import MultiSelectComponent from "../../components/MultiSelect/MultiSelect";
import Calendar from "../Calendar";
import SearchLeaguesInput from "../SearchLeaguesInput";
import LeaguesList from "../LeaguesList";
import CustomApplyButton from "../CustomApplyButton";
import { getAllDatesBetween } from "../../helpers/getAllDates";
import {
	fetchFixturesByLeaguesStart,
	matchSlitByDay,
	resetFixtures,
} from "../../redux/fixtures/fixturesActions";
import { fetchFormValues } from "../../redux/formValues/formValuesActions";
import { resetMapDetails } from "../../redux/map/mapActions";
import { resetFixtureDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { resetSidebarDetails } from "../../redux/sidebar/sidebarActions";

import "./filterByLeagues.scss";
import { selectFormValuesDates } from "../../redux/formValues/formValuesSelectors";
import { resetLive } from "../../redux/live/liveActions";
import {
	selectSelectedLeagues,
	selectSelectedStadiums,
	selectSelectedTeams,
} from "../../redux/leagues/leaguesSelectors";
import {
	fetchSelectedLeague,
	fetchSelectedStadium,
	fetchSelectedTeam,
} from "../../redux/leagues/leaguesActions";
import { selectFixturesFetching } from "../../redux/fixtures/fixturesSelectors";
import { selectDay } from "../../redux/formValues/formValuesActions";
//Components
// import Tag from "../Tag";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Mixpanel from "../../utils/mixPanel";
import CompetitionTabs from "../CompetitionTab";
import { competitionTabs } from "../../utils";
import FilterByLocation from "../FilterByLocation";
// import SelectedCompetition from "./Addons/SelectedCompetition";
// import SearchCompetition from "./Addons/SearchCompetition";
import SelectedLeagues from "../selectedLeagues";
import Ad from "../Ad/Ad";
const platform = typeof window !== "undefined" ? "web" : "mobile";
const FilterByLeagues = ({
	fetchFixturesByLeaguesStart,
	fetchFormValues,
	resetFixtureDetails,
	resetFixtures,
	resetMapDetails,
	resetSidebarDetails,
	dates,
	resetLive,
	selectedListLeagues,
	fetchSelectedLeague,
	isFetching,
	selectDay,
	fetchSelectedTeam,
	fetchSelectedStadium,
	selectSelectedTeams,
	selectSelectedStadiums,
	matchSlitByDay,
	onSearchBtnChange 
}) => {
	console.log("Filter League ==>", selectSelectedTeams, selectSelectedStadiums);
	const [inputVal, setInputVal] = useState("");
	const [errorMessage, setError] = useState("");
	// const [inputOptions, setInputOptions] = useState([]);
	const [searchBtns, setSearchBtns] = useState(4);
	const [startDate, setStartDate] = useState(new Date(dates[0]));
	const [endDate, setEndDate] = useState(new Date(dates[dates.length - 1]));
	// const data = useSelector((state) => state);
	const formValues = useSelector((state) => state.formValues);
	const [isSearchValid, setIsSearchValid] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch()
	useEffect(() => {
		if (onSearchBtnChange) {
		onSearchBtnChange(searchBtns);
		}
  	}, [searchBtns, onSearchBtnChange]);
	useEffect(() => {
		if (searchBtns === 1) {
			setIsSearchValid(inputVal.trim().length > 0);
		}
	}, [inputVal, searchBtns]);

	useEffect(() => {
		switch (searchBtns) {
			case 1:
				setIsSearchValid(selectedListLeagues.length > 0);
				break;
			case 2:
				setIsSearchValid(selectSelectedStadiums.length > 0);
				break;
			case 3:
				setIsSearchValid(selectSelectedTeams.length > 0);
				break;
			default:
				setIsSearchValid(false);
		}
	}, [
		searchBtns,
		selectedListLeagues,
		selectSelectedTeams,
		selectSelectedStadiums,
	]);

	useEffect(() => {
		if (formValues?.leagueSearch) {
			const [country, leagueName] = formValues?.leagueSearch.split(":");
			setInputVal(country);
			// console.log(country);
		}
	}, [formValues]);

	useEffect(() => {
		// const d = dates;
		setStartDate(new Date(dates[0]));
		setEndDate(new Date(dates[dates.length - 1]));
	}, [dates]);
	const handlePress = () => {
		setError("");
		resetLive();
		resetSidebarDetails();
		resetMapDetails();
		resetFixtureDetails();
		resetFixtures();
		matchSlitByDay({});
		const datesRage = Object.keys(getAllDatesBetween(startDate, endDate));
		// const currentDates = Object.keys(getAllDatesBetween(startDate, endDate));
		selectDay(moment(startDate).format("YYYY-MM-DD"));
		fetchFormValues({
			dates: datesRage,
			currentDate: datesRage[0],
			address: null,
			countryCode: "",
			searchedLocation: null,
		});
		fetchFixturesByLeaguesStart(() => setInputVal(""));
		history.push("/search");
		// Mixpanel.track(`Pressed wishlist`, {
		//   match: `${item?.home_team?.name} vs ${item?.away_team?.name}`,
		//   platform: platform,
		//   application_type: platform === "web" ? "web app" : "mobile app",
		//   status: `Fixture ${addWishlist ? "removed" : "added"} to wishlist`,
		// });
	};

	const handleErrorMessage = (error) => {
		setError(error);
		// setTimeout(() => {
		//   setError("");
		// }, 3000);
	};

	const onCloseTab = (item) => {
		let selectedLeagues = selectedListLeagues;

		const isIncludes = selectedListLeagues.find((league) => {
			if (league.league_id === item.league_id) return true;
			return false;
		});

		if (isIncludes) {
			selectedLeagues = selectedLeagues.filter((leg) => {
				if (leg.league_id !== item.league_id) return true;
				return false;
			});
		} else {
			selectedLeagues.push(item);
		}

		fetchSelectedLeague(selectedLeagues);
	};

	const [index, setIndex] = useState(1);

		return (
			<div className={`filter-by-leagues ${searchBtns === 2 || searchBtns === 3 ? 'reduced-height' : ''}`}>
				<h3 style={{marginBottom:'10px'}}>Search By</h3>
				<div className="search-btns-container">
				<button
					onClick={() => {
					Mixpanel.track("Pressed search by location", {
						platform: platform,
						application_type: platform === "web" ? "web app" : "mobile app",
					});
					dispatch(fetchSelectedLeague([]));
					dispatch(fetchSelectedStadium([]));
					dispatch(fetchSelectedTeam([]));
					dispatch(
						fetchFormValues({
						leagueSearch: "",
						})
					);
					setSearchBtns(4);
					}}
					className={`search-btn ${searchBtns === 4 && "active-btn"}`}
				>
					Location
				</button>
				<button
					onClick={() => {
				    localStorage.setItem('isComingFromOther', true)
					Mixpanel.track("Pressed tab in Advanced Search", {
						platform: platform,
						application_type: platform === "web" ? "web app" : "mobile app",
					});
					setSearchBtns(1);
					fetchSelectedStadium([]);
					fetchSelectedTeam([]);
					setError("");
					}}
					className={`search-btn ${searchBtns === 1 && "active-btn"}  `}
				>
					Competition
				</button>
				<button
					onClick={() => {
					localStorage.setItem('isComingFromOther', true)
					Mixpanel.track("Pressed tab in Advanced Search", {
						platform: platform,
						application_type: platform === "web" ? "web app" : "mobile app",
					});
					setSearchBtns(2);
					fetchSelectedLeague([]);
					fetchSelectedTeam([]);
					setError("");
					setInputVal("");
					}}
					className={`search-btn ${searchBtns === 2 && "active-btn"}  `}
				>
					Stadium
				</button>
				<button
					onClick={() => {
					localStorage.setItem('isComingFromOther', true)
					Mixpanel.track("Pressed tab in Advanced Search", {
						platform: platform,
						application_type: platform === "web" ? "web app" : "mobile app",
					});
					setSearchBtns(3);
					fetchSelectedLeague([]);
					fetchSelectedStadium([]);
					setError("");
					setInputVal("");
					}}
					className={`search-btn ${searchBtns === 3 && "active-btn"}  `}
				>
					Team
				</button>
				</div>

				{searchBtns === 4 ? 
				(
					<FilterByLocation setIndex={setIndex} onCountryClick={() => setSearchBtns(1)} />
				) : (
				<>
					<div className="filter-inputs">
					<Calendar
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						type="filterByLeagues"
					/>

					{searchBtns === 1 || searchBtns === null ? (
						<SearchLeaguesInput
						placeholder="Search by competitions / country"
						inputVal={inputVal}
						setInputVal={setInputVal}
						/>
					) : searchBtns === 2 ? (
						<div className="league-multi-select">
						<MultiSelectComponent
							placeholderText="Search by Stadium"
							searchBy="stadium"
							selectedOptions={selectSelectedStadiums}
							setSelectedOptions={fetchSelectedStadium}
						/>
						</div>
					) : (
						searchBtns === 3 && (
						<div className="league-multi-select">
							<MultiSelectComponent
							placeholderText="Search by Team"
							searchBy="team"
							selectedOptions={selectSelectedTeams}
							setSelectedOptions={fetchSelectedTeam}
							/>
						</div>
						)
					)}

					{errorMessage && <div className="error-message">{errorMessage}</div>}
					{(searchBtns === 2 || searchBtns === 3) && (
						<>
							<div className="">
								<CustomApplyButton
									text="Find Matches"
									isFetching={isFetching}
									disabled={!isSearchValid} // Disable if search is not valid
									handlePress={() => {
									if (searchBtns === 2 && selectSelectedStadiums.length > 0) {
										Mixpanel.track(`Searched matches`, {
										platform: platform,
										application_type:
											platform === "web" ? "web app" : "mobile app",
										searchType: "stadiums",
										});
										handlePress();
									} else if (
										searchBtns === 2 && 
										selectSelectedStadiums.length === 0
									) {
										handleErrorMessage("Select stadiums to search.");
									}

									if (searchBtns === 3 && selectSelectedTeams.length > 0) {
										Mixpanel.track(`Searched matches`, {
										platform: platform,
										application_type: 
											platform === "web" ? "web app" : "mobile app",
										searchType: "Teams",
										});
										handlePress();
									} else if (
										searchBtns === 3 && 
										selectSelectedTeams.length === 0
									) {
										handleErrorMessage("Select teams to search.");
									}
									}}
								/>
							</div>
							<div style={{
									// border:'1px solid black',
									// marginTop: shouldApplyMargin ? 60 : 10, 
								}}>
								<Ad slot="7944567579" styles={{ width: 390, height: 150}} />
							</div>
						</>
					)}
					</div>

					{searchBtns === 1 && (
					<>
						<div style={{ marginTop: "20px" }}>
						<CompetitionTabs
							tabs={competitionTabs}
							index={index}
							setIndex={setIndex}
							disabled={!isSearchValid} // Disable if search is not valid
							components={{
							1: (
								<LeaguesList 
								inputVal={inputVal} 
								type="filterByLeagues" 
								/>
							),
							2: (
								<div className="selected-competition">
								<div className="tags-container">
									{selectedListLeagues?.length ? (
									<SelectedLeagues
										tags={selectedListLeagues}
										onClose={onCloseTab}
									/>
									) : null}
								</div>
								<button
									className="deseelct-btn"
									onClick={() => {
									Mixpanel.track(`Cleared selected`, {
										platform: platform,
										application_type: 
											platform === "web" ? "web app" : "mobile app",
										searchType: "Competition",
									});
									fetchSelectedLeague([]);
									}}
								>
									Clear Selection
								</button>
								</div>
							),
							}}
							type="main"
						/>
						</div>
						{/*
					 	*/}
						<div className="filter-by-leagues-apply-btn">
						<CustomApplyButton
							text="Find Matches"
							isFetching={isFetching}
							disabled={!isSearchValid} // Disable if search is not valid
							handlePress={() => {
							if (selectedListLeagues?.length > 0) {
								Mixpanel.track(`Searched matches`, {
								platform: platform,
								application_type: 
									platform === "web" ? "web app" : "mobile app",
								searchType: "Leagues",
								});
								handlePress();
							} else {
								handleErrorMessage("Select competition to search.");
							}
							}}
						/>
						</div>
						 <div style={{
								// border:'1px solid black',
								// marginTop: shouldApplyMargin ? 60 : 10, 
							}}>
							<Ad slot="7944567579" styles={{ width: 390, height: 150 }} />
						</div>
					</>
					)}
				</>
				)}
			</div>
		);
};

const mapDispatchToProps = (dispatch) => ({
	fetchFixturesByLeaguesStart: (clearText) =>
		dispatch(fetchFixturesByLeaguesStart({ clearText })),
	fetchFormValues: (payload) => dispatch(fetchFormValues(payload)),
	resetFixtureDetails: () => dispatch(resetFixtureDetails()),
	resetFixtures: () => dispatch(resetFixtures()),
	resetMapDetails: () => dispatch(resetMapDetails()),
	resetSidebarDetails: () => dispatch(resetSidebarDetails()),
	resetLive: () => dispatch(resetLive()),
	fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
	selectDay: (payload) => dispatch(selectDay(payload)),
	fetchSelectedTeam: (payload) => dispatch(fetchSelectedTeam(payload)),
	fetchSelectedStadium: (payload) => dispatch(fetchSelectedStadium(payload)),
	matchSlitByDay: (payload) => dispatch(matchSlitByDay(payload)),
});

const mapStateToProps = createStructuredSelector({
	dates: selectFormValuesDates,
	selectedListLeagues: selectSelectedLeagues,
	selectSelectedTeams: selectSelectedTeams,
	selectSelectedStadiums: selectSelectedStadiums,
	isFetching: selectFixturesFetching,
});

FilterByLeagues.propTypes = {
	fetchFixturesByLeaguesStart: PropTypes.func,
	fetchFormValues: PropTypes.func,
	resetFixtureDetails: PropTypes.func,
	resetFixtures: PropTypes.func,
	resetMapDetails: PropTypes.func,
	resetSidebarDetails: PropTypes.func,
	dates: PropTypes.array,
	resetLive: PropTypes.func,
	selectedListLeagues: PropTypes.array,
	fetchSelectedLeague: PropTypes.func,
	isFetching: PropTypes.func,
	selectDay: PropTypes.func,
	fetchSelectedTeam: PropTypes.func,
	fetchSelectedStadium: PropTypes.func,
	selectSelectedTeams: PropTypes.func,
	selectSelectedStadiums: PropTypes.func,
	matchSlitByDay: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterByLeagues);
