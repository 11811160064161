export const getStoredLocation = (userLocation) => {
  const searchedLocation = localStorage.getItem("userSearchedLocation");
  return searchedLocation && searchedLocation !== "null" ? searchedLocation : userLocation;
};

export const saveSearchedLocation = (location) => {
  if (location) {
    localStorage.setItem("userSearchedLocation", location);
  }
};

export const clearSearchedLocation = () => {
  localStorage.removeItem("userSearchedLocation");
};
