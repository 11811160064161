import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
} from "react";
import Geocode from "react-geocode";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";

import AutoCompleteSearchBox from "../AutoCompleteSearchBox";
import Calendar from "../Calendar";
import RangeSlider from "../RangeSlider";
import CustomApplyButton from "../CustomApplyButton";
import {
	fetchFixturesByLocationStart,
	fetchFixturesByCountryStart,
	resetFixtures,
	checkUpdateFixtures,
	// fetchMatchesByCountryStart,
	// fetchIsCountry,
	matchSlitByDay,
} from "../../redux/fixtures/fixturesActions";
import { getAllDatesBetween } from "../../helpers/getAllDates";
import { fetchFormValues } from "../../redux/formValues/formValuesActions";
import { resetMapDetails } from "../../redux/map/mapActions";
import { resetFixtureDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { resetSidebarDetails } from "../../redux/sidebar/sidebarActions";
import {
	fetchSelectedLeague,
	checkUpdateLeagues,
	fetchSelectedStadium,
	fetchSelectedTeam,
} from "../../redux/leagues/leaguesActions";
import {
	selectFormValuesRadius,
	selectFormValuesDates,
	selectFormValuesSearchedLocation,
	selectFormValuesAddress,
	selectFormValuesCountryCode,
} from "../../redux/formValues/formValuesSelectors";
import { selectFixturesFetching } from "../../redux/fixtures/fixturesSelectors";
import "./filterByLocation.scss";
import { resetLive } from "../../redux/live/liveActions";
import { useHistory } from "react-router-dom";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";

import { selectDay } from "../../redux/formValues/formValuesActions";
import { Context } from "../../helpers/context/Context";
import { getUserLocation } from "../../helpers/getUserLocation";
import { fetchUserLocationStart, fetchUserLocationSuccess } from "../../redux/userLocation/userLocationActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { selectUserLocationAddress } from "../../redux/userLocation/userLocationSelectors";
import {getStoredLocation, saveSearchedLocation } from "../../helpers/locationHelper"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
// import Ad from "../Ad";
import Ad from "../Ad/Ad";
import Mixpanel from "../../utils/mixPanel";

const platform = typeof window !== "undefined" ? "web" : "mobile";
const countriesData = [
	{
		id: 1,
		name: "Germany",
		short_name: "DE",
		flag_icon: "https://flagcdn.com/w160/de.png",
	},
	{
		id: 2,
		name: "Spain",
		short_name: "ES",
		flag_icon: "https://flagcdn.com/w160/es.png",
	},
	{
		id: 3,
		name: "France",
		short_name: "FR",
		flag_icon: "https://flagcdn.com/w160/fr.png",
	},
	{
		id: 4,
		name: "Italy",
		short_name: "IT",
		flag_icon: "https://flagcdn.com/w160/it.png",
	},
	{
		id: 5,
		name: "USA",
		short_name: "US",
		flag_icon: "https://flagcdn.com/w160/us.png",
	},
	{
		id: 6,
		name: "England",
		short_name: "GB",
		flag_icon: "https://flagcdn.com/w160/gb.png",
	},
];

const FilterByLocation = ({
	fetchFixturesByLocationStart,
	// fetchFixturesByCountryStart,
	resetFixtureDetails,
	resetFixtures,
	resetMapDetails,
	resetSidebarDetails,
	fetchSelectedLeague,
	fetchSelectedStadium,
	fetchSelectedTeam,
	// checkUpdateFixtures,
	// checkUpdateLeagues,
	fetchFormValues,
	fetchFormValuesAll,
	dates,
	resetLive,
	isFetching,
	radius,
	coords,
	country_code,
	address,
	userLocation,
	selectDay,
	matchSlitByDay,
	onCountryClick
	// setIndex,
}) => {
	const leaguesRedux = useSelector((state) => state.leagues);
	const context = useContext(Context);
	// const dispatch = useDispatch();
	// const [isAllCountries, setIsAllCountries] = useState(false);
	const [startDate, setStartDate] = useState(new Date(dates[0]));
	const [endDate, setEndDate] = useState(new Date(dates[dates.length - 1]));
	// const [localAddress, setLocalAddress] = useState("");
	const userLocationAddress = useSelector(selectUserLocationAddress);
	const [localAddress, setLocalAddress] = useState(getStoredLocation(userLocationAddress));
	const [isLocationValid, setIsLocationValid] = useState(false);
	const [searchLocation, setSearchLocation] = useState(null);
	// useEffect(()=>{
	// 	const isComingFromOther = localStorage.getItem('isComingFromOther');
	// 	const loca = localStorage.getItem('userSearchedLocation')
	// 	if(isComingFromOther === 'true'){
	// 		if(loca){
	// 			setLocalAddress(loca)
	// 		}
	// 	}
	// 	localStorage.removeItem('isComingFromOther')
	// },[])
	useEffect(() => {
		if (localAddress.trim()) {
			setIsLocationValid(true);
		} else {
			setIsLocationValid(false);
		}
	}, [localAddress]);

	useEffect(() => {
		const getUserLoc = async () => {
			const loc = getStoredLocation(userLocationAddress);
			setLocalAddress(loc);  // Set the location address (e.g., "London, UK")
			try {
				// Geocode the address to get results
				const results = await geocodeByAddress(loc);

				let countryCode;
				results[0].address_components.forEach((x) => {
					if (x.types.includes("country")) {
						countryCode = x.short_name;
					}
				});

				// Get latitude and longitude
				const latLng = await getLatLng(results[0]);

				// Fetch form values and map details with the lat/lng and country code
				// fetchFormValues({ loc, searchedLocation: latLng, countryCode });
				// fetchMapDetails({ center: latLng });
			} catch (error) {
				console.error("Error fetching user location or geocoding:", error);
			}
		};

		getUserLoc();
	}, [userLocationAddress]);

	useEffect(() => {
		if (!localAddress) {
			setLocalAddress(userLocationAddress);
			// setSearchLocation(userLocationAddress)
			setIsLocationValid(true)
		}
	}, []);

	useEffect(() => {
		// const d = dates;
		setStartDate(new Date(dates[0]));
		setEndDate(new Date(dates[dates.length - 1]));
	}, [dates]);

	const history = useHistory();
	const location = coords ? coords : userLocation;
	console.log(location, userLocation);

	const handlePress = useCallback(
		(isAuto, data) => {
			context.setIsAllCountries(true);
			if (isFetching) return;
			resetLive();
			resetSidebarDetails();
			resetMapDetails();
			resetFixtureDetails();
			resetFixtures();
			matchSlitByDay({});
			// if (!localAddress) 
			// 	localStorage.setItem("userSearchedLocation", userLocationAddress);
			// else 
			// 	localStorage.setItem("userSearchedLocation", localAddress);

			const datesRage = Object.keys(getAllDatesBetween(startDate, endDate));
			const currentDates = Object.keys(getAllDatesBetween(startDate, endDate));
			selectDay(moment(startDate).format("YYYY-MM-DD"));

			fetchFormValues(datesRage, currentDates);
			fetchFixturesByLocationStart(context.isAllCountries);
			if (isAuto) {
				history.push(
					`/search?radius=${radius}&dates=${datesRage[0]},${
						datesRage[datesRage.length - 1]
					}&lat=${data.searchedLocation.lat}&lng=${
						data.searchedLocation.lng
					}&address=${data.address}&iso_a2=${data.countryCode}`
				);
			} else {
				history.push(
					`/search?radius=${radius}&dates=${datesRage[0]},${
						datesRage[datesRage.length - 1]
					}&lat=${location.lat}&lng=${
						location.lng
					}&address=${address}&iso_a2=${country_code}`
				);
			}
			Mixpanel.track(`Searched matches`, {
				"Search Type": "location",
				location: {
					lat: location.lat || data?.searchedLocation.lat,
					lng: location.lng || data?.searchedLocation.lng,
				},
				radius: `${radius} km`,
				start_date: `'${datesRage[0].toString()}'`,
				end_date: `'${datesRage[datesRage.length - 1]}'`,
				country: country_code,
				platform: platform,
				application_type: platform === "web" ? "web app" : "mobile app",
			});
		},
		[
			context,
			isFetching,
			resetLive,
			resetSidebarDetails,
			resetMapDetails,
			resetFixtureDetails,
			resetFixtures,
			matchSlitByDay,
			startDate,
			endDate,
			selectDay,
			fetchFormValues,
			fetchFixturesByLocationStart,
			history,
			radius,
			location,
			address,
			country_code,
			searchLocation
		]
	);

	const getLeagueData = useCallback((name, leagues) => {
		const country = name;
		const leagueData = leagues.filter(
			(league) =>
				String(league.country).toLocaleLowerCase() ===
				String(country).toLocaleLowerCase()
		);
		console.log("leagueData selct", leagueData);
		if (leagueData) {
			return leagueData;
		}
		return [];
	}, []);

	const handleInputChange = useCallback((value) => {
		setLocalAddress(value);
		setIsLocationValid(value.trim() !== ""); // Enable button only if input is valid
		// setSearchLocation(value)
		saveSearchedLocation(value); // Save new searched location

	}, []);

	return (
		<div className="filter-by-location">
			<div className="autoComplete-container">
				<AutoCompleteSearchBox
					handlePress={handlePress}
					locationString={localAddress || searchLocation}
					isFetching={isFetching}
					// setIndex
					onInputChange={handleInputChange} // Pass callback here
					onClear={() => setIsLocationValid(false)}
					onLocationSet={(address) => {
						console.log("Parent received location:------", address); // Debug
						localStorage.setItem('userSearchedLocation', address)
						setLocalAddress(address);
						// setSearchLocation(address); 
						setIsLocationValid(true); // Enable the button
						saveSearchedLocation(address);
					}}
				/>
			</div>
			<div className="countries-sections">
				{countriesData.map((ele) => (
					<img
						key={ele.id}
						src={ele.flag_icon}
						alt="flag-img"
						onError={(e) =>
							(e.target.src =
								"https://media.api-sports.io/football/teams/14834.png")
						}
						onClick={() => {
							localStorage.setItem('isComingFromOther', true)
							console.log("Name", ele);
							Mixpanel.track(`Pressed flag`, {
								platform: platform,
								application_type: platform === "web" ? "web app" : "mobile app",
								flag: ele.name,
							});
							setLocalAddress(ele.name);
							// setIndex(1);
							fetchFormValuesAll({
								leagueSearch: ele.name,
							});
							fetchSelectedLeague(
								getLeagueData(ele.name, leaguesRedux.leagues)
							);
							fetchSelectedStadium([]);
							fetchSelectedTeam([]);
							onCountryClick()
						}}
					/>
				))}
			</div>

			<Calendar
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				type="filterByLocation"
			/>
			<div className="slider-container">
				<div className="slider-title">Match Radius</div>
				<RangeSlider />
			</div>
			<div className="find-match-button">
				<CustomApplyButton
					text="Find Matches"
					handlePress={handlePress}
					isFetching={isFetching}
					disabled={!isLocationValid} // Disable if location is not valid
				/>
			</div>
			<div style={{
					// border:'1px solid black',
					// marginTop: shouldApplyMargin ? 60 : 10, 
					}}>
				<Ad slot="7944567579" styles={{ width: 390, height: 150 }} />
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	checkUpdateFixtures: () => dispatch(checkUpdateFixtures()),
	checkUpdateLeagues: () => dispatch(checkUpdateLeagues()),
	fetchFixturesByLocationStart: (payload) =>
		dispatch(fetchFixturesByLocationStart(payload)),
	fetchFixturesByCountryStart: (payload) =>
		dispatch(fetchFixturesByCountryStart(payload)),
	fetchFormValues: (datesRage, currentDates) =>
		dispatch(fetchFormValues({ dates: datesRage, currentDates })),
	fetchFormValuesAll: (data) => dispatch(fetchFormValues(data)),
	fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
	fetchSelectedStadium: (payload) => dispatch(fetchSelectedStadium(payload)),
	fetchSelectedTeam: (payload) => dispatch(fetchSelectedTeam(payload)),
	resetFixtureDetails: () => dispatch(resetFixtureDetails()),
	resetFixtures: () => dispatch(resetFixtures()),
	resetMapDetails: () => dispatch(resetMapDetails()),
	resetSidebarDetails: () => dispatch(resetSidebarDetails()),
	resetLive: () => dispatch(resetLive()),
	selectDay: (payload) => dispatch(selectDay(payload)),
	matchSlitByDay: (payload) => dispatch(matchSlitByDay(payload)),
});

const mapStateToProps = createStructuredSelector({
	sliderRadius: selectFormValuesRadius,
	dates: selectFormValuesDates,
	isFetching: selectFixturesFetching,
	radius: selectFormValuesRadius,
	coords: selectFormValuesSearchedLocation,
	userLocation: selectUserLocationCoords,
	address: selectFormValuesAddress,
	country_code: selectFormValuesCountryCode,
});

FilterByLocation.propTypes = {
	checkUpdateFixtures: PropTypes.func,
	checkUpdateLeagues: PropTypes.func,
	fetchFixturesByLocationStart: PropTypes.func,
	fetchFormValues: PropTypes.func,
	resetFixtureDetails: PropTypes.func,
	resetFixtures: PropTypes.func,
	resetMapDetails: PropTypes.func,
	resetSidebarDetails: PropTypes.func,
	fetchSelectedLeague: PropTypes.func,
	sliderRadius: PropTypes.number,
	dates: PropTypes.array,
	resetLive: PropTypes.func,
	fetchSelectedStadium: PropTypes.func,
	fetchSelectedTeam: PropTypes.func,
	fetchFormValuesAll: PropTypes.func,
	isFetching: PropTypes.bool,
	radius: PropTypes.number,
	curretDate: PropTypes.string,
	coords: PropTypes.object,
	address: PropTypes.string,
	country_code: PropTypes.string,
	userLocation: PropTypes.object,
	selectDay: PropTypes.func,
	matchSlitByDay: PropTypes.func,
	// setIndex: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(React.memo(FilterByLocation));
