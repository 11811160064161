// /* eslint-disable react/jsx-key */
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";
// import { FiSearch } from "react-icons/fi";
// import { RiCloseLine } from "react-icons/ri";
// import { createStructuredSelector } from "reselect";
// import PropTypes from "prop-types";
// import "./autoCompleteSearchBox.scss";
// import { connect, useSelector, useDispatch } from "react-redux";
// import { selectUserLocationAddress } from "../../redux/userLocation/userLocationSelectors";
// import { selectFormValuesAddress } from "../../redux/formValues/formValuesSelectors";
// import { fetchMapDetails } from "../../redux/map/mapActions";
// import { fetchFormValues } from "../../redux/formValues/formValuesActions";
// import { getUserLocation } from "../../helpers/getUserLocation";
// import {
//   fetchUserLocationStart,
//   fetchUserLocationSuccess,
// } from "../../redux/userLocation/userLocationActions";
// import { useRouteMatch } from "react-router-dom";
// import { MdMyLocation } from "react-icons/md";
// import Mixpanel from "../../utils/mixPanel";
// import {
//   selectIsSharedFixture,
//   selectIsWishlistFrom,
// } from "../../redux/sidebar/sidebarSelectors";
// import { set } from "react-ga";
// import { useLocation } from "react-router-dom/cjs/react-router-dom";
// import {setActionTriggered} from "../../redux/currentLoaction/currentLoactionActions";
// const platform = typeof window !== "undefined" ? "web" : "mobile";
// const AutoCompleteSearchBox = React.memo(
//   ({
//     userLocationAddress,
//     searchedLocationAddress,
//     fetchFormValues,
//     fetchMapDetails,
//     handlePress,
//     locationString,
//     isFetching,
//     fetchUserLocationStart,
//     onInputChange,
//     onClear,
//     onLocationSet,
//     isSharedFixture,
//     isWishlistFrom,
//   }) => {
//     const [localAddress, setLocalAddress] = useState(locationString || " ");
//     const [isFromFlag, setIsFromFlag] = useState(false);
//     const match = useRouteMatch();
//     const { path } = match;
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [suggestionsState, setSuggestionsState] = useState([]); // State to store suggestions
//     const dropdownRef = useRef(null); // Ref for detecting outside clicks
//     const location = useLocation();
//     const dispatch = useDispatch();
//     const actionTriggered = useSelector(state => state.currLoc);
//     const hasRun = useRef(false);
//     const [userCurrentLocation, setUserCurrentLocation] = useState(null);
//     // useEffect(() => {
//     // 	handleLocationPin();
//     // }, [locationString]);

//     useEffect(() => {
//       if (actionTriggered.actionTriggered) {
//         // Call handleLocationPin if the action was triggered
//         // handleLocationPin();
//          const inputField = document.getElementById("search");
//           if (inputField) {
//             // inputField.value = userCurrentLocation; // Set the value explicitly
//             inputField.value = localStorage.getItem("userSearchedLocation")
//           }
//           hasRun.current = true;
//         // Optionally reset the actionTriggered state after handling
//         dispatch(setActionTriggered(false)); // Reset the state to avoid calling it multiple times
//         localStorage.removeItem("userSearchedLocation")
//       }
//   }, [actionTriggered, dispatch, userLocationAddress, userCurrentLocation]);

//     useEffect(() => {
//       if (locationString && !isSharedFixture && !isWishlistFrom) {
//         setLocalAddress(locationString);
//       }
//     }, [locationString]);

//     useEffect(() => {
//       console.log(
//         searchedLocationAddress,
//         "searchedLocationAddress",
//         userLocationAddress,
//         "userLocationAddress"
//       );
//       if (path.includes("search") && !isSharedFixture && !isWishlistFrom) {
//         const sessionAddress = sessionStorage.getItem("search");
//         const input = document.getElementsByClassName("location-search-input");
//         input[0].addEventListener(
//           "keydown",
//           (event) => {
//             if (event.code == "Enter") {
//               // enterEvent&&enterEvent()
//             }
//           },
//           false
//         );
//         // setLocalAddress(searchedLocationAddress || sessionAddress || userLocationAddress);
//         // setLocalAddress(userLocationAddress);
//       }
//     }, [searchedLocationAddress, userLocationAddress]);

//     useEffect(() => {
//       // const { radius, dates, lat, lng, address, iso_a2, leagues } = getUrlParams(
//       //   window.location.href
//       // );
//       if (!path.includes("search") && !isSharedFixture && !isWishlistFrom) {
//         getUserLocation(fetchUserLocationStart)
//           .then((res) => {
//             setLocalAddress(res.address);
//             Mixpanel.track("Enable location", {
//               platform: platform,
//               application_type: platform === "web" ? "web app" : "mobile app",
//               address: res.address,
//             });
//             console.log(res, "getUserLocation");
//             fetchMapDetails({ center: res.coordinates });
//           })
//           .catch((err) => {
//             Mixpanel.track("Disabled location", {
//               platform: platform,
//               application_type: platform === "web" ? "web app" : "mobile app",
//             });

//             setLocalAddress("London, UK");
//             fetchMapDetails({ center: { lat: 51.5073509, lng: -0.1277583 } });
//           });
//       }
//     }, []);

//     useEffect(() => {
//       if (!isSharedFixture || !isWishlistFrom) {
//         setLocalAddress(locationString);
//         const input = document.getElementsByClassName("location-search-input");
//         input[0].addEventListener(
//           "keydown",
//           (event) => {
//             if (event.code === "Enter") {
//               // enterEvent&&enterEvent()
//             }
//           },
//           false
//         );
//         handleSelectFromFlag(locationString);
//         setIsFromFlag(true);
//       }
//     }, [locationString]);

//     useEffect(() => {
//       const handleClickOutside = (event) => {
//         if (
//           dropdownRef.current &&
//           !dropdownRef.current.contains(event.target) &&
//           isDropdownOpen
//         ) {
//           handleSelectFirstSuggestion();
//           setIsDropdownOpen(false);
//         }
//       };
//       document.addEventListener("mousedown", handleClickOutside);
//       return () => {
//         document.removeEventListener("mousedown", handleClickOutside);
//       };
//     }, [isDropdownOpen, suggestionsState]);

//     const handleSelectFirstSuggestion = () => {
//       const suggestionItem = document.querySelector(".suggestion-item");
//       const suggestions = suggestionItem ? suggestionItem.textContent : null;
//       handleSelect(suggestions);
//     };

//     console.log(
//       searchedLocationAddress,
//       "searchedLocationAddress",
//       userLocationAddress,
//       "userLocationAddress"
//     );

//     const handleChange = useCallback((address) => {
//       setLocalAddress(address);
//       setIsDropdownOpen(true);
//       Mixpanel.track("Pressed search bar", {
//         platform: platform,
//         application_type: platform === "web" ? "web app" : "mobile app",
//       });
//       if (typeof onInputChange === "function") {
//         onInputChange(address); // Notify parent of input change
//       }
//     }, []);

//     // const handleSelect = useCallback(
//     // 	(address) => {
//     // 		setLocalAddress(address);
//     // 		let countryCode;

//     // 		if (
//     // 			window.google &&
//     // 			window.google.maps &&
//     // 			!isSharedFixture &&
//     // 			!isWishlistFrom
//     // 		) {
//     // 			geocodeByAddress(address)
//     // 				.then((results) => {
//     // 					results[0].address_components.forEach((x) => {
//     // 						if (x.types.includes("country") || x.address_components) {
//     // 							countryCode = x.short_name;
//     // 						}
//     // 					});
//     // 					sessionStorage.setItem("search", `${address}`);
//     // 					return getLatLng(results[0]);
//     // 				})
//     // 				.then((latLng) => {
//     // 					Mixpanel.track(`Selected location`, {
//     // 						platform: platform,
//     // 						application_type: platform === "web" ? "web app" : "mobile app",
//     // 						searchedLocation: latLng,
//     // 					});
//     // 					console.log(
//     // 						"one -----------------------------------------------"
//     // 					);
//     // 					fetchFormValues({
//     // 						countryCode,
//     // 						address,
//     // 						searchedLocation: latLng,
//     // 					});
//     // 					fetchMapDetails({ center: latLng });
//     // 				})
//     // 				.catch((error) => {
//     // 					Mixpanel.track(`Error on Custom Location`, {
//     // 						platform: platform,
//     // 						application_type: platform === "web" ? "web app" : "mobile app",
//     // 						error,
//     // 					});
//     // 					console.error("Error", error);
//     // 				});
//     // 		} else {
//     // 			console.error("Google Maps API is not available.");
//     // 		}
//     // 	},
//     // 	[fetchFormValues, isSharedFixture, isWishlistFrom]
//     // );

//     const handleSelect = useCallback(
//       async (address) => {
//         try {
//           setLocalAddress(address);
//           let countryCode;

//           if (
//             window.google &&
//             window.google.maps &&
//             !isSharedFixture &&
//             !isWishlistFrom
//           ) {
//             const results = await geocodeByAddress(address);
//             results[0].address_components.forEach((x) => {
//               if (x.types.includes("country")) {
//                 countryCode = x.short_name;
//               }
//             });

//             sessionStorage.setItem("search", `${address}`);
//             const latLng = await getLatLng(results[0]);

//             if (address !== "London, UK") {
//               console.log("Valid location selected:", address);
//               fetchFormValues({
//                 countryCode,
//                 address,
//                 searchedLocation: latLng,
//               });
//               fetchMapDetails({ center: latLng });
//             } else {
//               console.log(
//                 "Skipping fetchFormValues for fallback location:",
//                 address
//               );
//             }
//             // Notify the parent component of the selected address
//             if (typeof onLocationSet === "function") {
//               onLocationSet(address); // This will update the parent's searchLocation state
//             }
//           } else {
//             console.error("Google Maps API is not available.");
//           }
//         } catch (error) {
//           console.error("Error during handleSelect:", error);
//         }
//       },
//       [fetchFormValues, fetchMapDetails, isSharedFixture, isWishlistFrom]
//     );

//     // const handleSelectFromFlag = useCallback(
//     // 	(address) => {
//     // 		setLocalAddress(address);
//     // 		let countryCode;
//     // 		if (
//     // 			window.google &&
//     // 			window.google.maps &&
//     // 			!isSharedFixture &&
//     // 			!isWishlistFrom
//     // 		) {
//     // 			geocodeByAddress(address)
//     // 				.then((results) => {
//     // 					results[0].address_components.forEach((x) => {
//     // 						if (x.types.includes("country") || x.address_components) {
//     // 							countryCode = x.short_name;
//     // 						}
//     // 					});
//     // 					sessionStorage.setItem("search", `${address}`);
//     // 					return getLatLng(results[0]);
//     // 				})
//     // 				.then((latLng) => {
//     // 					if (location.pathname === "/search") {
//     // 						let fixture = localStorage.getItem("fixture");
//     // 						fixture = JSON.parse(fixture);
//     // 						const { lat, lng } = fixture;
//     // 						fetchMapDetails({
//     // 							zoom: 13,
//     // 							center: { lat, lng },
//     // 							marker: fixture,
//     // 						});
//     // 					} else if (location.pathname === "/home") {
//     // 						let fixture = localStorage.getItem("fixture-wishlist");
//     // 						fixture = JSON.parse(fixture);
//     // 						const { lat, lng } = fixture;
//     // 						fetchMapDetails({
//     // 							zoom: 13,
//     // 							center: { lat, lng },
//     // 							marker: fixture,
//     // 						});
//     // 					} else {
//     // 						Mixpanel.track(`Pressed on current location`, {
//     // 							platform: platform,
//     // 							application_type:
//     // 								platform === "web" ? "web app" : "mobile app",
//     // 							searchedLocation: latLng,
//     // 						});
//     // 						console.log("two ------------------------------------");
//     // 						fetchFormValues({
//     // 							countryCode,
//     // 							address,
//     // 							searchedLocation: latLng,
//     // 						});
//     // 						fetchMapDetails({ center: latLng });
//     // 					}
//     // 					// setTimeout(() => {
//     // 					//   handlePress(true, {
//     // 					//     countryCode,
//     // 					//     address,
//     // 					//     searchedLocation: latLng,
//     // 					//   });
//     // 					//   setIsFromFlag(false);
//     // 					// }, 500);
//     // 				})
//     // 				.catch((error) => console.error("Error", error));
//     // 		} else {
//     // 			console.error("Google Maps API is not available.");
//     // 		}
//     // 	},
//     // 	[fetchFormValues, isSharedFixture, isWishlistFrom]
//     // );

//     const handleSelectFromFlag = useCallback(
//       async (address) => {
//         try {
//           setLocalAddress(address);
//           let countryCode;

//           if (
//             window.google &&
//             window.google.maps &&
//             !isSharedFixture &&
//             !isWishlistFrom
//           ) {
//             const results = await geocodeByAddress(address);
//             results[0].address_components.forEach((x) => {
//               if (x.types.includes("country")) {
//                 countryCode = x.short_name;
//               }
//             });

//             sessionStorage.setItem("search", `${address}`);
//             const latLng = await getLatLng(results[0]);

//             if (address !== "London, UK") {
//               console.log("Valid location from flag:", address);
//               fetchFormValues({
//                 countryCode,
//                 address,
//                 searchedLocation: latLng,
//               });
//               fetchMapDetails({ center: latLng });
//             } else {
//               if(userLocationAddress !== 'London, UK'){
//                 console.log('here---------', 'userLocationAddress', userLocationAddress)
//                   fetchFormValues({
//                   countryCode,
//                   address,
//                   searchedLocation: latLng,
//                 });
//                 fetchMapDetails({ center: latLng });
//               }
//               else{
//                   const LondonCords = {
//                     countryCode: "GB",
//                     coordinates: {
//                       lat: 51.5073509,
//                       lng: -0.1277583,
//                     },
//                     address: "London, UK",
//                   };
//                 fetchFormValues({
//                   countryCode : LondonCords.countryCode,
//                   address : LondonCords.address ,
//                   searchedLocation: LondonCords.coordinates,
//                 });
//                 fetchMapDetails({ center: LondonCords.coordinates });
//                 console.log(
//                   "Skipping fetchFormValues for fallback location:",
//                   address
//                 );
//               }
//              if(!location.pathname === "/search"){
//                fetchFormValues({
//                 countryCode,
//                 address,
//                 searchedLocation: latLng,
//               });
//               fetchMapDetails({ center: latLng });
//              }
//             }
//           } else {
//             console.error("Google Maps API is not available.");
//           }
//         } catch (error) {
//           console.error("Error during handleSelectFromFlag:", error);
//         }
//       },
//       [fetchFormValues, fetchMapDetails, isSharedFixture, isWishlistFrom]
//     );

//     const handleLocationPin = useCallback(() => {
//       let countryCode;
//       getUserLocation(fetchUserLocationStart)
//         .then((res) => {
//           setLocalAddress(res.address);
//           setUserCurrentLocation(res.address);
//           if (typeof onLocationSet === "function") {
//             onLocationSet(res.address);
//           }
//           geocodeByAddress(res.address)
//             .then((results) => {
//               results[0].address_components.forEach((x) => {
//                 if (x.types.includes("country") || x.address_components) {
//                   countryCode = x.short_name;
//                 }
//               });
//               sessionStorage.setItem("search", `${res.address}`);
//               return getLatLng(results[0]);
//             })
//             .then((latLng) => {
//               console.log("three --------------------------------------");
//               fetchFormValues({
//                 countryCode,
//                 address: res.address,
//                 searchedLocation: latLng,
//               });
//               fetchMapDetails({ center: latLng });
//             })
//             .catch((error) => console.error("Error", error));
//         })
//         .catch((err) => {
//           console.log("error fetching user location", err);
          
//           setLocalAddress("London, UK");
//           if (typeof onLocationSet === "function") {
//             console.log(
//               "Notifying parent with fallback location:",
//               "London, UK"
//             );
//             onLocationSet("London, UK");
//           }
//           const LondonCords = {
//             countryCode: "GB",
//             coordinates: {
//               lat: 51.5073509,
//               lng: -0.1277583,
//             },
//             address: "London, UK",
//           };
//           fetchFormValues({
//             countryCode,
//             address: LondonCords.address,
//             searchedLocation: LondonCords.coordinates,
//           });
//           fetchUserLocationSuccess(LondonCords);
//           fetchMapDetails({ center: LondonCords.coordinates });
//         });
//     },[fetchFormValues, fetchMapDetails, onLocationSet]);

//     return (
//       <PlacesAutocomplete
//         value={localAddress == "undefined" ? "" : localAddress}
//         onChange={handleChange}
//         onSelect={handleSelect}
//         googleCallbackName="myCallbackFunc"
//       >
//         {(
//           { getInputProps, suggestions, getSuggestionItemProps, loading },
//           i
//         ) => {
//           return (
//             <div key={i} ref={dropdownRef}>
//               <div className="location-search-input-container">
//                 <FiSearch className="search-icon" size={20} />
//                 <input
//                   id="search"
//                   {...getInputProps({
//                     placeholder: "Search by location",
//                     className: "location-search-input",
//                   })}
//                 />

//                 {/* {localAddress && ( */}
//                 <>
//                   <MdMyLocation
//                     className="location-icon"
//                     size={22}
//                     onClick={handleLocationPin}
//                   />

//                   <RiCloseLine
//                     onClick={() => {
//                       setLocalAddress("");
//                       setIsDropdownOpen(false); // Optionally close dropdown
//                       if (typeof onClear === "function") {
//                         onClear(); // Notify the parent
//                       }
//                     }}
//                     className="input-close-icon"
//                     size={25}
//                   />
//                 </>
//                 {/* )} */}
//               </div>

//               {isDropdownOpen && (
//                 <div className="autocomplete-dropdown-container">
//                   <div className="absolute-list">
//                     {loading && <div></div>}

//                     {suggestions.map((suggestion) => {
//                       const className = suggestion.active
//                         ? "suggestion-item--active"
//                         : "suggestion-item";
//                       const style = suggestion.active
//                         ? { backgroundColor: "#fafafa", cursor: "pointer" }
//                         : { backgroundColor: "#ffffff", cursor: "pointer" };
//                       return (
//                         <div
//                           {...getSuggestionItemProps(suggestion, {
//                             className,
//                             style,
//                           })}
//                           key={suggestion.index}
//                         >
//                           <span>{suggestion.description}</span>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 </div>
//               )}
//             </div>
//           );
//         }}
//       </PlacesAutocomplete>
//     );
//   }
// );

// const mapStateToProps = createStructuredSelector({
//   userLocationAddress: selectUserLocationAddress,
//   searchedLocationAddress: selectFormValuesAddress,
//   isSharedFixture: selectIsSharedFixture,
//   isWishlistFrom: selectIsWishlistFrom,
// });

// const mapDispatchToProps = (dispatch) => ({
//   fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
//   fetchFormValues: (payload) => dispatch(fetchFormValues(payload)),
//   fetchUserLocationStart: () => dispatch(fetchUserLocationStart()),
// });

// AutoCompleteSearchBox.propTypes = {
//   userLocationAddress: PropTypes.string,
//   searchedLocationAddress: PropTypes.string,
//   setDestination: PropTypes.func,
//   fetchMapDetails: PropTypes.func,
//   fetchFormValues: PropTypes.func,
//   enterEvent: PropTypes.func,
//   locationString: PropTypes.string,
//   onInputChange: PropTypes.func, // Add this line
//   onClear: PropTypes.func,
//   onLocationSet: PropTypes.func,
//   isWishlistFrom: PropTypes.bool,
//   isSharedFixture: PropTypes.bool,
//   fetchUserLocationStart: PropTypes.func,
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(AutoCompleteSearchBox);


import React, { useState, useEffect, useRef, useCallback } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { FiSearch } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
import { MdMyLocation } from "react-icons/md";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { fetchFormValues } from "../../redux/formValues/formValuesActions";
import { fetchUserLocationStart, fetchUserLocationSuccess } from "../../redux/userLocation/userLocationActions";
import { selectUserLocationAddress } from "../../redux/userLocation/userLocationSelectors";
import "./autoCompleteSearchBox.scss";
import { getUserLocation } from "../../helpers/getUserLocation";
import { getStoredLocation, saveSearchedLocation } from "../../helpers/locationHelper";
import Mixpanel from "../../utils/mixPanel";
const platform = typeof window !== "undefined" ? "web" : "mobile";



const AutoCompleteSearchBox = ({ 
  fetchFormValues, 
  fetchMapDetails, 
  onClear, 
  onLocationSet,
  isSharedFixture,
  isWishlistFrom,
  onInputChange,
  handlePress,
  locationString  
}) => {
  const dispatch = useDispatch();
  const userLocationAddress = useSelector(selectUserLocationAddress);
  // const [localAddress, setLocalAddress] = useState(userLocationAddress || "");
    const [localAddress, setLocalAddress] = useState(getStoredLocation(userLocationAddress));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const { areFixturesDetailsFetched } = useSelector((state) => state.sidebar);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  useEffect(() => {
       if (locationString && !isSharedFixture && !isWishlistFrom) {
        //  setLocalAddress(locationString);
       }
  }, [locationString]);
  useEffect(() => {
      const getUserLoc = async () => {
        const loc = getStoredLocation(userLocationAddress);
        saveSearchedLocation(loc)
        setLocalAddress(loc);  // Set the location address (e.g., "London, UK")
          try {
              // Geocode the address to get results
              const results = await geocodeByAddress(loc);
              let countryCode;
              results[0].address_components.forEach((x) => {
                  if (x.types.includes("country")) {
                      countryCode = x.short_name;
                  }
              });

              // Get latitude and longitude
              const latLng = await getLatLng(results[0]);

              // Fetch form values and map details with the lat/lng and country code
              if(!areFixturesDetailsFetched){
                fetchFormValues({ loc, searchedLocation: latLng, countryCode });
                fetchMapDetails({ center: latLng });
              }
          } catch (error) {
              console.error("Error fetching user location or geocoding:", error);
          }
      };

      getUserLoc();
  }, [userLocationAddress, areFixturesDetailsFetched]);
  useEffect(() => {
    if (!userLocationAddress) {
      dispatch(fetchUserLocationStart());
    } else {
      console.log('userLocationAddress-----', userLocationAddress)
      const loc = localStorage.getItem('userSearchedLocation')
      console.log('value from local stroage', localStorage.getItem('userSearchedLocation'))
      // setLocalAddress(searchedLocation)
      if(loc && loc !== userLocationAddress){
        setLocalAddress(loc)
      }
      else setLocalAddress(userLocationAddress);
    }
  }, [userLocationAddress]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setHighlightedIndex(-1);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (address) => {
    setLocalAddress(address);
    setIsDropdownOpen(true);
    setHighlightedIndex(-1);
    Mixpanel.track("Pressed search bar", {
      platform: platform,
      application_type: platform === "web" ? "web app" : "mobile app",
    });
    if (typeof onInputChange === "function") {
        onInputChange(address);
    }
  };

  const handleSelect = async (address) => {
    let latlng;
    try {
      setLocalAddress(address);
      const results = await geocodeByAddress(address);
      let countryCode;
      results[0].address_components.forEach((x) => {
        if (x.types.includes("country")) {
          countryCode = x.short_name;
        }
      });
      const latLng = await getLatLng(results[0]);
      latlng = latLng
      fetchFormValues({ address, searchedLocation: latLng, countryCode });
      fetchMapDetails({ center: latLng });
      setIsDropdownOpen(false);
      setHighlightedIndex(-1);
      saveSearchedLocation(address);
      if (typeof onLocationSet === "function") {
        onLocationSet(address); // This will update the parent's searchLocation state
      }
      Mixpanel.track(`Selected location`, {
 				platform: platform,
 				application_type: platform === "web" ? "web app" : "mobile app",
 				searchedLocation: latLng,
      });
    } catch (error) {
       Mixpanel.track(`Error on Custom Location`, {
 			 	platform: platform,
 			 	application_type: platform === "web" ? "web app" : "mobile app",
 			 	searchedLocation: latlng,
       });
      console.error("Error selecting location:", error);
    }
  };

  const handleLocationPin = async () => {
    try {
      console.log('handleLocationPin')
      const locationData = await getUserLocation(fetchUserLocationStart);
      console.log('locationData', locationData)
      setLocalAddress(locationData.address);
      dispatch(fetchUserLocationSuccess(locationData));
      fetchFormValues({ address: locationData.address, searchedLocation: locationData.coordinates });
      fetchMapDetails({ center: locationData.coordinates });
      if (typeof onLocationSet === "function") {
        onLocationSet(locationData.address);
      }
    } catch (error) {
      setLocalAddress("London, UK");
       const LondonCords = {
        countryCode: "GB",
        coordinates: {
          lat: 51.5073509,
          lng: -0.1277583,
        },
        address: "London, UK",
      };
      fetchFormValues({
        countryCode : LondonCords.countryCode,
        address : LondonCords.address ,
        searchedLocation: LondonCords.coordinates,
      });
      fetchMapDetails({center : LondonCords.coordinates})
      if (typeof onLocationSet === "function") {
        onLocationSet("London, UK");
      }
      console.error("Error fetching user location:", error);
    }
  };

     const handleKeyDown = async (event, suggestions) => {
      if (!suggestions.length) return;
        let newIndex = highlightedIndex;
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            newIndex = highlightedIndex < suggestions.length - 1 ? highlightedIndex + 1 : highlightedIndex;
            break;
          case "ArrowUp":
            event.preventDefault();
            newIndex = highlightedIndex > 0 ? highlightedIndex - 1 : 0;
            break;
          case "Enter":
            event.preventDefault();
            if (highlightedIndex >= 0) {
              handleSelect(suggestions[highlightedIndex].description);
            }
            return;
          default:
            return;
        }
      setHighlightedIndex(newIndex);

      // Update map when navigating with arrow keys
      if (newIndex >= 0 && suggestions[newIndex]) {
        try {
          const results = await geocodeByAddress(suggestions[newIndex].description);
          const latLng = await getLatLng(results[0]);
          // Only update map view, don't update form values yet
          fetchFormValues({ 
            address: suggestions[newIndex].description, 
            searchedLocation: latLng 
          });
          fetchMapDetails({ center: latLng });
        } catch (error) {
          console.error("Error fetching location details:", error);
        }
      }
  };

  return (
    <PlacesAutocomplete value={localAddress} onChange={handleChange} onSelect={handleSelect} googleCallbackName="myCallbackFunc">
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div ref={dropdownRef} className="location-search-input-container">
          <div className="input-wrapper">
            <FiSearch className="search-icon" size={20} />
            <input {...getInputProps({ placeholder: "Search by location", className: `location-search-input ${isIOS ? "ios-search-input" : ""}`,  onKeyDown: (event) => handleKeyDown(event, suggestions) })} />
            <MdMyLocation className="location-icon" size={22} onClick={handleLocationPin} />
            <RiCloseLine className="input-close-icon" size={25} 
              onClick={() => {
                setLocalAddress(""); 
                setIsDropdownOpen(false); 
                if (typeof onClear === "function") {
                  onClear(); 
                }}} 
            />
          </div>
          {isDropdownOpen && (
            <div className="autocomplete-dropdown-container">
              <div className="absolute-list">
                {loading && <div></div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div {...getSuggestionItemProps(suggestion, {
                        className, 
                        style
                      })} 
                        key={suggestion.index}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchFormValues: (payload) => dispatch(fetchFormValues(payload)),
});

export default connect(null, mapDispatchToProps)(AutoCompleteSearchBox);
