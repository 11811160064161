import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { FaRegClock } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import information from "../../assets/images/information.png";
import StadiumImageBlue from "../../assets/images/blueMarker.png";
import { useSpring, animated } from "react-spring";
import {
	fetchFixture,
	fetchSupportersDetailsStart,
	resetFixtureDetails,
} from "../../redux/fixtureDetails/fixtureDetailsActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import {
	selectSearchBy,
	selectDynamicFixturesList,
	selectFixturesLeagues,
	selectFixturesFetching,
	selectFixturesByLeagues,
	selectFicturesLeaguesArray,
} from "../../redux/fixtures/fixturesSelectors";
import {
	selectInternalMap,
	selectCircle,
	selectMarkerSelected,
	selectMarker,
	selectIsMobile,
} from "../../redux/map/mapSelectors";
import MobileFixturesListItem from "../MobileFixturesListItem.js/MobileFixturesListItem";
import "./mobileFixturesList.scss";
import MobileFixturesListButtons from "../MobileFixturesListButtons";
import { fetchFilteringValues } from "../../redux/filteringValues/filteringValuesActions";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import { AiOutlineCaretLeft } from "react-icons/ai";
import Spinner from "../Spinner";
import LiveMatchesDates from "../LiveMatchesDates";
import ErrorMessage from "../ErrorMessage";
import Ad from "../Ad/Ad";
import {
	selectLiveMatches,
	selectIsFetchingLiveMatches,
	selectFixturesForLive,
	selectLiveMatchesDate,
	selectLiveMatch,
	selectShowOnlyLive,
	selectLiveFixturesLeagues,
	selectIsMobileSidebarVisible,
} from "../../redux/live/liveSelectors";
import {
	fetchIsMobileSidebarVisible,
	fetchLiveMatch,
	resetLiveDetails,
	showOnlyLiveMatches,
} from "../../redux/live/liveActions";
import {
	selectFavMatches,
	selectShowOnlyFav,
} from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import { showOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesActions";
import {
	animateCamera,
	getLiveMatches,
	splitFixturesByLeagues,
} from "../../utils";

import {
	selectFormValuesDates,
	selectorSelectDay,
} from "../../redux/formValues/formValuesSelectors";
import {
	selectIsItFiltered,
	selectSortBy,
} from "../../redux/filteringValues/filteringValuesSelectors";
import { selectNormalizedLeaguesList } from "../../redux/leagues/leaguesSelectors";
import { countriesCoords } from "../../assets/countriesCoords";
import {
	selectAreFixturesDetailsFetched,
	selectIsWishlistFrom,
} from "../../redux/sidebar/sidebarSelectors";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import { selectFixture } from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import { resetMapDetails } from "../../redux/map/mapActions";
import MatchesDates from "../MatchesDates";
// TO SHOW MIDDLE GREEN ARROW
import {
	selectMobileFixture,
	resetMobileFixture,
} from "../../redux/fixtures/fixturesActions";
import { selectedMobileFixture } from "../../redux/fixtures/fixturesSelectors";
import { selectMatches } from "../../redux/fixtures/fixturesSelectors";
import { setFixture, setLeagues } from "../../redux/fixtures/fixturesActions";
import { selectFormValuesSearchedLocation } from "../../redux/formValues/formValuesSelectors";

//NEXT BUTTON
import { selectDay } from "../../redux/formValues/formValuesActions";

//HELPER FUNC
import { sortByLeague } from "../../helpers/sortByLeague";

//COMPONENTS
import MatchNotFound from "../MatchNotFound";
import { GiConsoleController } from "react-icons/gi";
import { Context } from "../../helpers/context/Context";
import Mixpanel from "../../utils/mixPanel";
const platform = typeof window !== "undefined" ? "web" : "mobile";
function MobileFixturesList(props) {
	const {
		fixtures,
		fetchSidebarDetails,
		fetchFixture,
		width,
		fetchMapDetails,
		searchBy,
		setFilterModal,
		setSortModal,
		fetchFilteringValues,
		deviceLocation,
		markerSelected,
		map,
		circle,
		fetchSupportersDetailsStart,
		resetFixtureDetails,
		type,
		liveMatches,
		isFetchingLiveMatches,
		isFetchingFixtures,
		fetchIsMobileSidebarVisible,
		fixturesForLive,
		liveDate,
		fetchLiveMatch,
		liveMatch,
		favMatchesIds,
		showOnlyFav,
		areFixturesDetailsFetched,
		isMobileSidebarVisible,
		resetLiveDetails,
		showOnlyLiveMatches,
		showOnlyFavMatches,
		onlyLiveMatches,
		isItFiltered,
		leagues,
		fixture,
		fixturesLeagues,
		liveFixturesLeagues,
		dates,
		resetMapDetails,
		selectMobileFixture,
		selectedMobileFixture,
		currentSelectedDay,
		selectDay,
		setFixture,
		setLeagues,
		matches,
		isFetching,
		sortBy,
		marker,
		isMobile,
		isWishlistFrom,
	} = props;
	// console.log("isWishlistFrom", fixtures);
	const currentIndex = useRef(0);
	const currentFix = useRef(null);
	const [index, setIndex] = useState(null);
	const [isArrowOnList, setIsArrowOnList] = useState(true);
	const [isLeague, setLeague] = useState(true);
	const { insideRadiusFixtures, outsideRadiusFixtures } = fixtures;
	const context = useContext(Context);
	const league = useSelector((state) => state.leagues);
	const mapData = useSelector((state) => state.map);
	const [isPulling, setIsPulling] = useState(false);
	const [pullDistance, setPullDistance] = useState(0);
	const listRef = useRef(null);
	const pullDistanceRef = useRef(0);
	const springStyle = useSpring({
		transform: `translateY(${isPulling ? pullDistance : 0}px)`,
	});
	let isAllCountries = context.isAllCountries;
	let commonData;
	isAllCountries
		? (commonData = insideRadiusFixtures?.concat(outsideRadiusFixtures))
		: (commonData = insideRadiusFixtures);

	const hasSelectedOptions = (league) => {
		return (
			league?.selectedTeams?.length > 0 ||
			league?.selectedStadiums?.length > 0 ||
			league?.selectedLeagues?.length > 0
		);
	};

	useEffect(() => {
		if (currentFix && currentFix.current) {
			const myElement = document.getElementById(
				`item-${currentFix.current.fixture_id || currentFix.current.id}`
			);
			var topPos = myElement?.offsetTop;
			if (document.getElementById("snap-point")) {
				document.getElementById("snap-point").scrollTop =
					type === "live" ? topPos - 65 : topPos - 30;
			}
		}
	}, [areFixturesDetailsFetched, isMobileSidebarVisible]);

	useEffect(() => {
		if (fixturesForLive && fixturesForLive[liveDate]) {
			const cond = Object.values(fixturesForLive[liveDate])
				.flat()
				.some((x) => favMatchesIds.includes(x._id));
			if (!cond) {
				showOnlyFavMatches(false);
			}
		}
	}, [favMatchesIds, fixturesForLive]);

	const fixList =
		type !== "live"
			? [...insideRadiusFixtures, ...outsideRadiusFixtures]
			: new Date(liveDate).getDate() === new Date().getDate()
			? fixturesForLive[liveDate] &&
			  Object.values(
					getLiveMatches(fixturesForLive[liveDate], liveMatches || {})
			  ).flat()
			: fixturesForLive &&
			  fixturesForLive[liveDate] &&
			  Object.values(fixturesForLive[liveDate]).flat();
	if (searchBy !== "leagues" && fixList && fixList.length) {
		if (type !== "live" && !markerSelected) {
			// fixList.splice(insideRadiusFixtures.length, 0, {
			//   title: "Outside Radius",
			//   fixture_id: 2
			// });
		}
	}
	let list =
		type === "live"
			? splitFixturesByLeagues(
					fixList,
					leagues,
					showOnlyFav,
					onlyLiveMatches,
					favMatchesIds
			  ) || []
			: fixList || [];
	const totalCount = list.length;

	if (searchBy !== "leagues" && list && list.length && !markerSelected) {
		// list.splice(0, 0, {
		//   title: "All Matches Scroll Down To Focus",
		//   fixture_id: 1
		// });
	}

	useEffect(() => {
		// SELECT ALL STADIUMS FOR MOBILE FIRST INIT
		//START
		resetMapDetails();

		// let markers = [];

		// list.forEach((item) => {
		//   if (item?.fixtures?.length) {
		//     markers = markers.concat(item.fixtures);
		//   }
		// });
		// fetchMapDetails({
		//   marker: markers

		// });
		const arrow = document.getElementById("arrow");
		document.getElementById("snap-point") &&
			(document.getElementById("snap-point").scrollTop = 0);
		//END
		document.querySelector("#snap-point") &&
			document.querySelector("#snap-point").addEventListener("scroll", (e) => {
				const { x, y } = document
					?.querySelector("#snap-point")
					?.getBoundingClientRect();
				console.log(
					"MobileFixtureList doc",
					x,
					y,
					x + 30,
					document?.elementFromPoint(x, y),
					document?.elementFromPoint(x, y)?.id
				);
				const id = document?.elementFromPoint(x, y)?.id?.split("-")[1];
				console.log("MobileFixtureList", id);
				if (id === undefined || id === 1) {
					setIsArrowOnList(true);
				} else {
					setIsArrowOnList(false);
				}

				if (+id) {
					// FIRST CONDITION SELECT ALL
					if (id == "1") {
						///ALL MATCHES
						window.requestAnimationFrame(() => {
							arrow.style.top = "27px";
						});
						// resetMapDetails();
						// resetFixtureDetails();
						selectMobileFixture(index);
						setIndex(id);
						const searchOptions = hasSelectedOptions(league);
						if (searchOptions) {
							handleLeaguesMap(currentSelectedDay);
						}
						let markers = [];
						list.forEach((item) => {
							markers = markers.concat(item);
						});
						setLeague(true);
						// fetchMapDetails({
						//   marker: markers
						// });
						return;
					}

					if (id === index) {
						selectMobileFixture(index);
					} else {
						let fix_id = id;
						setIndex(id);
						selectMobileFixture(fix_id);

						// let currentFixByLeague = null;
						let fix = null;
						// list.forEach((item) => {
						//   if (item.fixture_id === parseInt(fix_id)){
						//     currentFixByLeague = item;
						//   }
						// });

						// SELECT ONE MATCH
						// if (!currentFixByLeague) {
						//   let scrolledLeague = list.find(
						//     (l) => l.league_id === parseInt(id)
						//   );
						//   fix = scrolledLeague?.find(
						//     (f) =>
						//       f.league_id === parseInt(fix_id) || f.id === parseInt(fix_id)
						//   );
						//   window.requestAnimationFrame(() => {
						//     arrow.style.top = "27px";
						//   });
						// } else {
						//SELECT STADIUM BY LEAGUE
						// GAME
						currentFix.current = list.find(
							(e) => e.fixture_id === parseInt(fix_id)
						);
						fix = list.find((e) => e.fixture_id === parseInt(fix_id));
						window.requestAnimationFrame(() => {
							arrow.style.top = "36px";
						});
						// }

						if (fix) {
							if ("title" in fix) {
								if (fix.title !== "hide") {
									if (type === "live") {
										fetchLiveMatch(null);
										const currentLeague = leagues[fix.id];
										const country =
											currentLeague?.country_code || currentLeague?.region;
										const coords = country
											? countriesCoords[country.toLowerCase()]
											: null;
										if (map) {
											if (coords) {
												animateCamera([coords], map);
											}
											const zoom = map.getZoom();
											map.setZoom(zoom - 18);
										}
									} else {
										fetchFixture(null);
										resetFixtureDetails();
										if (searchBy !== "leagues") {
											if (circle && map) {
												map.fitBounds(circle.getBounds(), 0);
											}
										} else {
											// console.log(searchBy,'------------------------------------------380')
											// animateCamera(
											//   [
											//     ...insideRadiusFixtures,
											//     ...outsideRadiusFixtures,
											//     { ...deviceLocation },
											//   ],
											//   map
											// );
										}
									}
								}
							} else {
								if (type === "live") {
									if (!liveMatch || fix?.fixture_id !== liveMatch?.fixture_id) {
										fetchLiveMatch(fix);
									}
								} else {
									if (!fixture || fix?.fixture_id !== fixture?.fixture_id) {
										if (fix?.fixtures?.length) {
											fix.fixtures.forEach((item) => {
												fetchFixture(item);
											});
										} else {
											fetchFixture(fix);
										}
									}
								}
								fetchMapDetails({
									marker: fix,
									zoom: 14,
									center: { lat: fix?.lat, lng: fix?.lng },
								});
								// handleSupporterInfoClick(fix, "scroll");
							}
						}
					}
				}
			});
	}, [totalCount, context.mobileDateChange]);

	const handleSupporterInfoClick = (fixture, ty, start) => {
		resetFixtureDetails();
		if (fixture?.fixtures?.length) {
			// const markers = [...fixture.fixtures];
			// // CALC CENTER CORD
			// const middleLat = fixture?.fixtures.reduce((acc, cur) => {
			//   if (cur.lat) {
			//     acc.lat = acc.lat + cur.lat;

			//   }
			//   if (cur.lng) {
			//     acc.lng = acc.lng + cur.lng;
			//   }

			//   return acc;
			// }, { lat: 0, lng: 0 });

			// console.log('test', middleLat);
			// SET TO STORE
			fetchMapDetails({
				marker: fixture.fixtures,
				zoom: 8,
				// center: { lat: middleLat.lat / fixture.fixtures.length, lng: middleLat.lng / fixture.fixtures.length }
			});
			// ANIMATE CAM
			// animateCamera(markers, map);
		} else {
			fetchMapDetails({
				marker: fixture,
				zoom: 14,
				center: { lat: fixture?.lat, lng: fixture?.lng },
			});
		}

		// if (!fixture.distance && map) {
		//   if (type === "live") {
		//     resetLiveDetails();
		//     const markers = Object.values(fixturesForLive[liveDate]).flat();
		//     animateCamera(markers, map);
		//   } else {
		//     if (searchBy !== "leagues" && circle) {
		//       // map.fitBounds(circle.getBounds(), 0);
		//     } else {
		//       const markers = [
		//         ...insideRadiusFixtures,
		//         ...outsideRadiusFixtures,
		//         { ...deviceLocation },
		//       ];

		//       animateCamera(markers, map);
		//     }
		//   }
		// }

		if (ty === "live") {
			if (fixture.distance) {
				resetLiveDetails();

				if (ty === "click") {
					fetchLiveMatch(fixture);

					fetchIsMobileSidebarVisible({
						isMobileSidebarVisible: true,
						isFormVisible: false,
					});
				} else {
					if (fixture.location) {
						fetchSupportersDetailsStart("live");
					}
				}
			}
		} else {
			if (ty === "click") {
				fetchFixture(fixture);

				fetchSidebarDetails({
					areFixturesDetailsFetched: true,
					areFixturesFetched: false,
				});
			}
		}
		if (ty === "click") {
			const myElement = document.getElementById(`item-${fixture.fixture_id}`);
			var topPos = myElement.offsetTop;
			if (document.getElementById("snap-point")) {
				document.getElementById("snap-point").scrollTop = topPos;
			}
		}
		Mixpanel.track(`Viewed match info`, {
			match: `${fixture?.home_team?.name} vs ${fixture?.away_team?.name}`,
			platform: platform,
			application_type: platform === "web" ? "web app" : "mobile app",
		});
	};

	const handleShowAllMatchesClick = () => {
		currentIndex.current = 0;
		if (document.getElementById("snap-point")) {
			document.getElementById("snap-point").scrollTop = 0;
		}
		fetchFilteringValues({
			isItFiltered: false,
			selectedLeagues:
				type === "home"
					? Object.values(fixturesLeagues)
					: liveFixturesLeagues[liveDate],
		});

		fetchMapDetails({ marker: null, markerSelected: false });

		if (type === "home") {
			fetchSidebarDetails({ areFixturesDetailsFetched: false });

			if (
				(searchBy === "leagues" && map) ||
				(map && isEmpty(insideRadiusFixtures))
			) {
				let markers = [
					...insideRadiusFixtures,
					...outsideRadiusFixtures,
					{ ...deviceLocation },
				];

				animateCamera(markers, map);
			} else {
				if (circle && map) {
					map.fitBounds(circle.getBounds(), 0);
				}
			}
		} else {
			if (new Date(liveDate).getDate() === new Date().getDate()) {
				showOnlyLiveMatches({
					onlyLiveMatches:
						onlyLiveMatches ||
						showOnlyFav ||
						isItFiltered ||
						isEmpty(liveMatches)
							? false
							: true,
				});
			}
			showOnlyFavMatches(false);
			fetchLiveMatch(null);
		}
	};

	useEffect(() => {
		if (list && list.length && !markerSelected) {
			currentIndex.current = 0;

			const currentIdx =
				isItFiltered || showOnlyFav || onlyLiveMatches
					? 0
					: currentIndex.current;
			if (list[currentIdx]) {
				if ("title" in list[currentIdx]) {
					fetchLiveMatch(null);
					fetchMapDetails({
						marker: null,
					});
				} else {
					fetchLiveMatch(list[currentIdx]);
				}
			}
		}
	}, [list.length, showOnlyFav, isItFiltered, onlyLiveMatches]);

	useEffect(() => {
		if (showOnlyFav || isItFiltered || onlyLiveMatches) {
			setTimeout(() => {
				if (document.getElementById("snap-point")) {
					document.getElementById("snap-point").scrollTop = 0;
				}
			}, 300);
		}
	}, [showOnlyFav, isItFiltered, onlyLiveMatches]);

	const handlePress = (type) => {
		// if (dates.length === 1) {
		fetchSidebarDetails({
			areFixturesFetched: false,
		});
		// }

		// const index = dates.findIndex((d) => {
		//   if (d === currentSelectedDay) return true;
		// });
		// if (type === "up") {
		//   if (index + 1 === dates.length) {
		//     const day = dates[0];
		//     selectDay(day);
		//     setFixture(matches[day]);
		//     setLeagues(matches[day].leagues);
		//     fetchSidebarDetails({
		//       areFixturesDetailsFetched: false,
		//     });
		//   } else {
		//     const day = dates[index + 1];
		//     selectDay(day);
		//     setFixture(matches[day]);
		//     setLeagues(matches[day].leagues);
		//     fetchSidebarDetails({
		//       areFixturesDetailsFetched: false,
		//     });
		//   }
		// }
	};
	useEffect(() => {
		if (isMobile) {
			// console.log("65");
			const fixture = list.find(
				(item) => item.fixture_id === marker.fixture_id
			);
			handleSupporterInfoClick(fixture, "click");
		}
	}, [isMobile]);

	// Function to fetch map details
	// const fetchMapWithDelay = (fixture, context) => {
	//   const { lat, lng } = fixture;

	//   return setTimeout(() => {
	//     fetchMapDetails({
	//       zoom: 6,
	//       center: { lat, lng },
	//       markerSelectedOnHover: fixture,
	//     });
	//     context.setIsFirstTime(null);
	//   }, 500);
	// };

	// Inside your component
	// useEffect(() => {
	//   const searchOptions = hasSelectedOptions(league);

	//   let timerId;

	//   if (
	//     searchOptions &&
	//     commonData.length > 0 &&
	//     !mapData?.markerSelectedOnHover &&
	//     !mapData?.marker &&
	//     context.isFirstTime
	//   ) {
	//     const fixture = commonData[0];

	//     timerId = fetchMapWithDelay(fixture, context);
	//   }

	//   return () => {
	//     if (timerId) {
	//       clearTimeout(timerId);
	//     }
	//   };
	// }, [league, mapData, commonData, currentSelectedDay]);
	useEffect(() => {
		const listElement = listRef.current;
		if (listElement) {
			listElement.addEventListener("touchstart", handleTouchStart);
			listElement.addEventListener("touchend", handleTouchEnd);
		}
		return () => {
			if (listElement) {
				listElement.removeEventListener("touchstart", handleTouchStart);
				listElement.removeEventListener("touchend", handleTouchEnd);
			}
		};
	}, [league]);
	const handleTouchStart = (e) => {
		if (listRef.current) {
			const startY = e.touches[0].clientY;
			listRef.current.addEventListener("touchmove", (moveEvent) =>
				handleTouchMove(moveEvent, startY)
			);
		}
	};

	const handleTouchMove = (e, startY) => {
		const currentY = e.touches[0].clientY;
		const distance = currentY - startY;
		if (distance > 0) {
			pullDistanceRef.current = distance;
			setIsPulling(true);
			setPullDistance(distance);
		}
	};

	const handleTouchEnd = () => {
		const distance = pullDistanceRef.current;
		if (distance > 100) {
			const searchOptions = hasSelectedOptions(league);
			if (searchOptions) {
				handleLeaguesMap(currentSelectedDay);
			} else {
				resetMapDetails();
				resetFixtureDetails();
				selectMobileFixture(index);
			}
		}
		pullDistanceRef.current = 0;
		setIsPulling(false);
		setPullDistance(0);
	};

	const handleLeaguesMap = (date) => {
		// Check if matches and matches[date] exist
		if (!matches || !matches[date]) {
			console.warn(`No data found for the date: ${date}`);
			return;
		}

		const firstDayFixtures = [
			...matches[date].insideRadiusFixtures,
			...matches[date].outsideRadiusFixtures,
		];

		// If no fixtures exist, return early
		if (firstDayFixtures.length === 0) {
			console.warn(`No fixtures found for the date: ${date}`);
			return;
		}

		const markers = firstDayFixtures
			.filter(
				(fixture) =>
					fixture?.venue &&
					fixture?.location &&
					fixture?.location?.lat &&
					fixture?.location?.lng
			)
			.map((fixture) => ({
				lat: fixture.location.lat,
				lng: fixture.location.lng,
			}));

		// markers.push({ ...deviceLocation });
		// If no valid markers found, return early
		if (markers.length === 0) {
			console.warn(`No valid markers found for the date: ${date}`);
			return;
		}

		// Check if Google Maps API is loaded and if the map object exists
		if (
			typeof window.google === "undefined" ||
			typeof window.google.maps === "undefined" ||
			!map
		) {
			console.error("Google Maps API or map is not available.");
			return;
		}

		const bounds = markers.reduce((bounds, marker) => {
			return bounds.extend(marker);
		}, new window.google.maps.LatLngBounds());

		// Ensure map object is valid before calling fitBounds
		setTimeout(() => {
			if (map && window.google) {
				if (markers.length === 1) {
					// If there's only one marker, center and set zoom to 12
					const singleMarkerPosition = new window.google.maps.LatLng(
						markers[0].lat,
						markers[0].lng
					);
					map.setCenter(singleMarkerPosition);
					map.setZoom(12);
				} else {
					// If there are multiple markers, fit bounds to show all markers
					map.fitBounds(bounds);
					 window.google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
						if (map.getZoom() > 6) {
							map.setZoom(7);
						}
					});
				}
			} else {
				console.error("Map object is not available.");
			}
		}, 0);
	};

	return (
		// <animated.div style={springStyle} ref={listRef} className="mobile-fixtures-list">
		<div className="mobile-fixtures-list-container">
			{isFetchingFixtures || (isFetching && width < 1000 && <Spinner />)}

			{((insideRadiusFixtures.length && type !== "live") ||
				(outsideRadiusFixtures.length && type !== "live") ||
				type === "live") &&
			fixList.length ? (
				<>
					<div className="btn-container">
						<MobileFixturesListButtons
							currentIndex={currentIndex}
							handleShowAllMatchesClick={handleShowAllMatchesClick}
							setFilterModal={setFilterModal}
							setSortModal={setSortModal}
							type={type}
							list={list}
						/>
					</div>
					<MatchesDates isShow={commonData.length > 0} />

					{type === "live" ? (
						<div className="live-matches-dates-container">
							<LiveMatchesDates />
						</div>
					) : null}
					{type === "live" && !fixList && isFetchingLiveMatches ? (
						<Spinner styles={{ alignItems: "flex-start" }} />
					) : (
						<div
							className="mobile-fixtures-list mobile-fixtures-design"
							id="snap-point"
						>
							<div
								id="arrow"
								className={isArrowOnList ? "count-arrow" : "count-arrow-match"}
							>
								<div>
									<AiOutlineCaretLeft
										size={30}
										color={"#29a762"}
										className={`mobile-caret ${
											type === "live" && "caret-live"
										}`}
									/>
								</div>
							</div>
							<div
								id={`item-${1}`}
								className={`mobile-fixture-list-title 
                  ${true === "hide" && "hide-item"}`}
							>
								<div className="all-matches-sect">
									<img
										className="mobile-stadium-icon"
										src={StadiumImageBlue}
										alt="marker"
									/>
									<span className="gl-title">{`ALL MATCHES(${list.length})  `}</span>
								</div>
							</div>

							{/* <div className="all-matches-sect">
                <img
                  className="mobile-stadium-icon"
                  src={StadiumImageBlue}
                  alt="marker"
                />
                <span className="gl-title">{`ALL MATCHES(${list.length})  `}</span>
              </div> */}

							{list &&
								list.length &&
								list
									.sort((a, b) =>
										sortBy === "distance"
											? a.distance - b.distance
											: sortBy === "priority"
											? a.leaguesPriority - b.leaguesPriority
											: a.event_timestamp - b.event_timestamp
									)
									.map((fixture, index) => {
										const { fixture_id, id } = fixture || {};
										return (
											<Sentry.ErrorBoundary
												key={fixture_id || id}
												fallback={<ErrorMessage />}
											>
												<MobileFixturesListItem
													index={index}
													type={type}
													id={fixture_id || id}
													normalizedLeagues={leagues}
													callback={() =>
														handleSupporterInfoClick(fixture, "click", index)
													}
													item={fixture}
													selectedMobileFixture={selectedMobileFixture}
												/>
											</Sentry.ErrorBoundary>
										);
									})}
							{/* map(
                  ({ league, leagueName, fixtures, league_id, _id }, index) => {
                    if (!fixtures) return null;
                    return (
                      <>
                        <div
                          key={`${league_id}-${index}-${fixtures.length}`}
                          id={`item-${league_id || _id}`}
                          className={`mobile-fixture-list-title 
                        ${league_id === "hide" && "hide-item"}`}
                        >
                          <div
                            className="game-list"
                            id={`item-${league_id || _id}`}
                          >
                            <span className="gl-title">
                              <img
                                src={league.logo}
                                onError={(e) => (e.target.src = league.logo)}
                              ></img>
                              {leagueName}
                            </span>
                            <div className={"count"}>
                              <span className="gl-total">
                                {fixtures.length}
                              </span>
                            </div>
                          </div>
                        </div>
                        {fixtures */}

							{/* </>
                    // );
                  }
                )} */}
						</div>
					)}
				</>
			) : (
				<>
					<div className="btn-container"></div>
					{/* <MatchesDates /> */}
					<div className="mobile-card-wrapper">
						<MatchNotFound
							handlePressUp={() => handlePress("up")}
							dates={dates}
							currentSelectedDay={currentSelectedDay}
						/>
					</div>
				</>
			)}
			<div className="ads-view">
				<Ad
					slot="7575657019"
					styles={{ marginTop: 0, width: 320, height: 60 }}
				/>
			</div>
			{/* </animated.div> */}
		</div>
	);
}

const mapDispatchToProps = (dispatch) => ({
	showOnlyLiveMatches: (payload) => dispatch(showOnlyLiveMatches(payload)),
	showOnlyFavMatches: (payload) => dispatch(showOnlyFav(payload)),
	fetchFixture: (fixture) => dispatch(fetchFixture(fixture)),
	fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
	fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
	fetchFilteringValues: (payload) => dispatch(fetchFilteringValues(payload)),
	fetchSupportersDetailsStart: (payload) =>
		dispatch(fetchSupportersDetailsStart(payload)),
	fetchIsMobileSidebarVisible: (payload) =>
		dispatch(fetchIsMobileSidebarVisible(payload)),
	resetFixtureDetails: () => dispatch(resetFixtureDetails()),
	fetchLiveMatch: (payload) => dispatch(fetchLiveMatch(payload)),
	resetLiveDetails: () => dispatch(resetLiveDetails()),
	resetMapDetails: () => dispatch(resetMapDetails()),
	selectMobileFixture: (payload) => dispatch(selectMobileFixture(payload)),
	resetMobileFixture: () => dispatch(resetMobileFixture()),
	selectDay: (payload) => dispatch(selectDay(payload)),
	setFixture: (payload) => dispatch(setFixture(payload)),
	setLeagues: (payload) => dispatch(setLeagues(payload)),
});

const mapStateToProps = (state) => (
	console.log("Mobile State==>", state),
	{
		favMatchesIds: selectFavMatches(state),
		showOnlyFav: selectShowOnlyFav(state),
		markerSelected: selectMarkerSelected(state),
		isItFiltered: selectIsItFiltered(state),
		searchBy: selectSearchBy(state),
		leagues: selectNormalizedLeaguesList(state),
		isMobileSidebarVisible: selectIsMobileSidebarVisible(state),
		areFixturesDetailsFetched: selectAreFixturesDetailsFetched(state),
		fixtures: selectDynamicFixturesList(state),
		fixture: selectFixture(state),
		onlyLiveMatches: selectShowOnlyLive(state),
		deviceLocation: selectUserLocationCoords(state),
		map: selectInternalMap(state),
		circle: selectCircle(state),
		liveMatches: selectLiveMatches(state),
		fixturesForLive: selectFixturesForLive(state),
		isFetchingLiveMatches: selectIsFetchingLiveMatches(state),
		isFetchingFixtures: selectFixturesFetching(state),
		liveDate: selectLiveMatchesDate(state),
		liveMatch: selectLiveMatch(state),
		width: selectWidth(state),
		fixturesLeagues: selectFixturesLeagues(state),
		liveFixturesLeagues: selectLiveFixturesLeagues(state),
		dates: selectFormValuesDates,
		selectedLeagues: selectFicturesLeaguesArray(state),
		fixturesByLeagues: selectFixturesByLeagues(state),
		selectedMobileFixture: selectedMobileFixture(state),
		dates: selectFormValuesDates(state),
		currentSelectedDay: selectorSelectDay(state),
		matches: selectMatches(state),
		searchLocation: selectFormValuesSearchedLocation(state),
		sortBy: selectSortBy(state),
		marker: selectMarker(state),
		isMobile: selectIsMobile(state),
		isWishlistFrom: selectIsWishlistFrom(state),
	}
);

MobileFixturesList.propTypes = {
	fixturesLeagues: PropTypes.object,
	isMobileSidebarVisible: PropTypes.bool,
	areFixturesDetailsFetched: PropTypes.bool,
	isWishlistFrom: PropTypes.bool,
	liveFixturesLeagues: PropTypes.object,
	showOnlyLiveMatches: PropTypes.func,
	showOnlyFavMatches: PropTypes.func,
	liveMatch: PropTypes.object,
	fixture: PropTypes.object,
	markerSelected: PropTypes.object,

	fixtures: PropTypes.object,
	resetLiveDetails: PropTypes.func,
	isItFiltered: PropTypes.bool,
	deviceLocation: PropTypes.object,
	onlyLiveMatches: PropTypes.bool,
	fetchSidebarDetails: PropTypes.func,
	fetchFixture: PropTypes.func,
	fetchMapDetails: PropTypes.func,
	searchBy: PropTypes.string,
	itemRef: PropTypes.object,
	setFilterModal: PropTypes.func,
	setSortModal: PropTypes.func,
	fetchFilteringValues: PropTypes.func,
	width: PropTypes.number,
	leagues: PropTypes.object,
	map: PropTypes.object,
	circle: PropTypes.object,
	fetchSupportersDetailsStart: PropTypes.func,
	resetFixtureDetails: PropTypes.func,
	type: PropTypes.string,
	liveMatches: PropTypes.object,
	fixturesForLive: PropTypes.object,
	isFetchingLiveMatches: PropTypes.bool,
	isFetchingFixtures: PropTypes.bool,
	fetchIsMobileSidebarVisible: PropTypes.func,
	liveDate: PropTypes.string,
	fetchLiveMatch: PropTypes.func,
	favMatchesIds: PropTypes.array,
	showOnlyFav: PropTypes.bool,
	fixturesByLeagues: PropTypes.object,
	selectedLeagues: PropTypes.array,
	selectedMobileFixture: PropTypes.number,
	searchLocation: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileFixturesList);
