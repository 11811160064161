import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import getDistance from "geolib/es/getDistance";
import { useInView } from "react-intersection-observer";
import UserIcon from "../../assets/images/userIcon.png";
import StadiumImage from "../../assets/images/blackMarker.png";
import StadiumImageBlue from "../../assets/images/blueMarker.png";
import { ReactComponent as SelectedStadiumImage } from "../../assets/images/pinWithFlag.svg";
import { ReactComponent as PubIcon } from "../../assets/images/pub.svg";
import { ReactComponent as RestaurantIcon } from "../../assets/images/restaurant.svg";
import { ReactComponent as HotelIcon } from "../../assets/images/hotel.svg";
import {
  selectFormValuesSearchedLocation,
  selectFormValuesRadius,
} from "../../redux/formValues/formValuesSelectors";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import {
  selectSearchBy,
  selectFixturesFetching,
  selectFixturesList,
} from "../../redux/fixtures/fixturesSelectors";
import {
  selectMarker,
  selectZoom,
  selectMapCenter,
  selectInternalMap,
  selectMarkerSelectedOnHover,
  selectCircle,
  selectMarkerSelected,
  selectAttractionMarkerSelected,
} from "../../redux/map/mapSelectors";
import {
  fetchMapDetails,
  fetchMapDetailsOnAttraction,
} from "../../redux/map/mapActions";
import SupporterInfoButton from "../SupporterInfoButton";
import MapControls from "../MapControls";
import IntroMessage from "../IntroMessage";
import ShowAllMatches from "../ShowAllMatches";
import Spinner from "../Spinner";
import {
  selectAttractions,
  selectSupporters,
} from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import { selectAreFixturesDetailsFetched } from "../../redux/sidebar/sidebarSelectors";
import MobileSearchBar from "../MobileSearchBar";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import {
  fetchFixture,
  resetMatchDetails,
} from "../../redux/fixtureDetails/fixtureDetailsActions";
import MobileSearchDetails from "../MobileSearchDetails";
import LiveMarker from "../LiveMarker";
import "./maps.scss";
import {
  selectLiveMatch,
  selectLiveMatchOnHover,
  selectIsLiveKeepFetching,
  selectShowOnlyLive,
} from "../../redux/live/liveSelectors";
import {
  fetchLiveMatch,
  fetchLiveMatchOnHover,
  openDetailsSection,
  resetLiveDetails,
} from "../../redux/live/liveActions";
import {
  selectShowOnlyFav,
  selectFavMatches,
} from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import HelpSupporterBtn from "../HelpSupporterBtn";
import { animateCamera } from "../../utils";
import MatchDetails from "../MatchDetails";
import FixtureDetails from "../FixtureDetails";
import { Context } from "../../helpers/context/Context";
import { useContext } from "react";
import AttractionModal from "../AttractionModal/attraction-modal";
import { fetchAttractionRecomendationTypeData } from "../../redux/attractions/attractionActions";
import useSupercluster from "use-supercluster";
import distance from "@turf/distance";
import Mixpanel from "../../utils/mixPanel";
const platform = typeof window !== "undefined" ? "web" : "mobile";
const isMobile =
  /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
    navigator.userAgent
  );

// Adjust clustering options for mobile devices
const clusteringOptions = isMobile
  ? { radius: 100, maxZoom: 10, minPoints: 3 }
  : { radius: 100, maxZoom: 20, minPoints: 3 };

const UserLocation = () => (
  <div className="userLocation-icon">
    <img
      className="userLocation"
      src={UserIcon}
      alt="user location"
      loading="lazy"
      onError={(e) =>
        (e.target.src = "https://media.api-sports.io/football/teams/14834.png")
      }
    />
  </div>
);

const Pub = ({
  onClick,
  isSelectedAtraction,
  name,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div
    className="marker-container"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {isSelectedAtraction && (
      <div className="attraction-popup">
        {name}
        {/* Add your additional information here */}
      </div>
    )}
    <PubIcon
      className={` ${isSelectedAtraction ? "iconOnHover" : "supporterIcon"} `}
      onClick={onClick}
    />

    {/* <div className="pub-flag-img-container">
      <img className="pub-flag-img" src={logoUrl} alt="logo" onError={(e) => e.target.src = "https://media.api-sports.io/football/teams/14834.png"} />
      </div>
      <div className="pub-flag-distance-container">
      {getDistance(supporterLocation, stadiumLocation) > 1000
        ? getDistance(supporterLocation, stadiumLocation) / 1000 + "Km"
        : getDistance(supporterLocation, stadiumLocation) + "m"}
    </div> */}
  </div>
);

const Restaurant = ({
  onClick,
  isSelectedAtraction,
  name,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div
    className="marker-container"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {isSelectedAtraction && (
      <div className="attraction-popup">
        {name}
        {/* Add your additional information here */}
      </div>
    )}
    <RestaurantIcon
      className={` ${isSelectedAtraction ? "iconOnHover" : "supporterIcon"} `}
      onClick={onClick}
    />
  </div>
  // <div>
  // <RestaurantIcon className="supporterIcon" onClick={onClick} />
  // </div>
);

const Hotel = ({
  onClick,
  isSelectedAtraction,
  name,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div
    className="marker-container"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {isSelectedAtraction && <div className="attraction-popup">{name}</div>}
    <HotelIcon
      className={` ${isSelectedAtraction ? "iconOnHover" : "supporterIcon"} `}
      onClick={onClick}
    />
  </div>
);

const Stadium = ({
  fixture: { venue },
  showStadiumName,
  callback,
  handleMouseEnter,
}) => (
  <div onClick={callback} onMouseEnter={() => handleMouseEnter()}>
    <img
      className="stadiumIcon"
      src={StadiumImage}
      alt="marker"
      loading="lazy"
      onError={(e) => (e.target.src = StadiumImage)}
    />
    {showStadiumName(venue)}
  </div>
);

const StadiumBlue = ({
  handleMouseEnter,
  handleMouseLeave,
  callback,
  fixture: { venue },
  showStadiumName,
}) => (
  <div
    onClick={callback}
    onMouseEnter={() => handleMouseEnter()}
    onMouseLeave={() => handleMouseLeave()}
  >
    <img
      className="stadiumIcon"
      src={StadiumImageBlue}
      alt="marker"
      loading="lazy"
      onError={(e) => (e.target.src = StadiumImageBlue)}
    />
    {showStadiumName(venue)}
  </div>
);

const matchesToday = {};

const SelectedStadium = ({
  fixture,
  callback,
  handleMouseLeave,
  handleMouseEnter,
  showStadiumName,
  flagUp,
}) => {
  const context = useContext(Context);
  const { ref: myRef, inView: elementIsVisible } = useInView();

  useEffect(() => {
    if (elementIsVisible) {
      context.setMatchesToday((matchesToday) => ({
        ...matchesToday,
        [fixture.fixture_id]: true,
      }));
    }
  }, [context.setMatchesToday, elementIsVisible, fixture.fixture_id]);

  if (!fixture.lat && !fixture.lng) return null;
  return (
    <div
      ref={myRef}
      onClick={callback}
      // onTouchStart={handleTouchStart}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      {flagUp(fixture)}
      {showStadiumName(fixture?.venue)}
    </div>
  );
};
let googleCircle;

const circle = (
  map,
  radius,
  searchBy,
  { lat, lng },
  fetchMapDetails,
  mapCircle
) => {
  if (map && lat) {
    if (googleCircle || searchBy === "leagues") {
      googleCircle.setMap(null);
      if (mapCircle) {
        fetchMapDetails({ circle: null });
      }
    }

    if (searchBy !== "leagues") {
      const latLng = new window.google.maps.LatLng(lat, lng);
      googleCircle = new window.google.maps.Circle({
        strokeColor: "#2e2e2e",
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillOpacity: 0,
        map,
        center: { lat, lng },
        radius: radius * 1000,
      });

      fetchMapDetails({ circle: googleCircle });
    }
  }
};

let polylines = [];

const polyline = (
  selectedMarker,
  supporters,
  areFixturesDetailsFetched,
  map
) => {
  if (polylines.length) {
    for (let i = 0; i < polylines.length; i++) {
      polylines[i].setMap(null);
    }
  }
  if (selectedMarker && supporters) {
    var lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1,
      strokeWeight: 1,
      scale: 4,
    };
    const { lat, lng } = selectedMarker;
    for (let i = 0; i < supporters.length; i++) {
      const coords = [supporters[i].location, { lat, lng }];
      const flightPath = new window.google.maps.Polyline({
        path: coords,

        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
      });

      polylines.push(flightPath);

      flightPath.setMap(map);
    }
  }
};
const Marker = ({ count, onClick, isSelected, markerSelectedOnHover }) => {
  const fixture = markerSelectedOnHover;
  if (isSelected && fixture) {
    return (
      <div
        style={{ backgroundColor: "white" }}
        onClick={onClick}
        className="cluster-container"
      >
        <SelectedStadiumImage className={`flagIcon`} />
        <div className="flag-container">
          <img
            className="flag-img"
            src={fixture?.home_team.logo}
            alt="logo"
            loading="lazy"
            onError={(e) => (e.target.src = fixture?.home_team.logo)}
          />
          <img
            className="flag-img"
            src={fixture?.away_team.logo}
            alt="logo"
            loading="lazy"
            onError={(e) => (e.target.src = fixture?.away_team.logo)}
          />
        </div>
        <p>({count})</p>
      </div>
    );
  } else {
    return (
      <div
        style={{ backgroundColor: "white" }}
        onClick={onClick}
        className="cluster"
      >
        <img src={StadiumImage} alt="marker" loading="lazy" />
        <p>({count})</p>
      </div>
    );
  }
};
const Maps = ({
  userLocationCoords,
  searchedLocationCoords,
  originalFixturesList,
  radius,
  selectedMarker,
  zoom,
  center,
  fetchMapDetails,
  map,
  searchBy,
  markerSelectedOnHover,
  mapCircle,
  supporters,
  areFixturesDetailsFetched,
  width,
  fetchFixture,
  isFetchingFixtures,
  onMarkerClick,
  type,
  liveMatch,
  liveMatchOnHover,
  fetchLiveMatch,
  fetchLiveMatchOnHover,
  resetMatchDetails,
  resetLiveDetails,
  openDetailsSection,
  isLiveKeepFetching,
  itemRef,
  showOnlyFav,
  insideRadiusFixtures,
  outsideRadiusFixtures,
  onlyLiveMatches,
  favMatchesIds,
  setFilterModal,
  attractions,
  fetchAttractionRecomendationTypeData,
  attractionMarkerSelectedOnHover,
  fetchMapDetailsOnAttraction,
  isWishlistFrom,
}) => {
  const markers =
    [...(insideRadiusFixtures || []), ...(outsideRadiusFixtures || [])] || [];

  const context = useContext(Context);
  const [bounds1, setBounds1] = useState(null);
  const [camCenter, setCamCenter] = useState({ ...center });
  const [isLeagues, setLeague] = useState(false);
  const [attractionModal, setAttractionModal] = useState(false);
  const [attraction, setAttraction] = useState(null);
  const boundsRef = useRef(null);
  const { isFetchingOnDate } = useSelector((state) => state.userLocation);
  const [isMapLoaded, setIsMapLoaded] = useState(true);
  const { marker } = useSelector((state) => state.map);

  const coords =
    type === "live"
      ? userLocationCoords
      : searchedLocationCoords && searchedLocationCoords.lat
      ? searchedLocationCoords
      : userLocationCoords;

  let markerList = showOnlyFav
    ? markers?.filter((x) => x.lat && x.lng && favMatchesIds.includes(x._id))
    : onlyLiveMatches
    ? markers?.filter((x) => x.lat && x.lng && x.events)
    : markers?.filter((x) => x.lat && x.lng);
  context.isAllCountries
    ? (markerList = markerList)
    : (markerList = insideRadiusFixtures);
  const adjustMarkerPositions = (markers) => {
    const positionCounts = {};
    const adjustedMarkers = [];
    const offsetValue = 0.003; // Adjust this value as needed

    markers.forEach((marker) => {
      const { lat, lng } = marker;
      const key = `${lat},${lng}`;

      if (!positionCounts[key]) {
        positionCounts[key] = 0; // Initialize count for this position
      }

      const offset = positionCounts[key] * offsetValue; // Calculate the offset based on count
      positionCounts[key] += 1; // Increment the count for this position

      adjustedMarkers.push({
        ...marker,
        lat: lat + offset, // Adjust latitude
        lng: lng + offset, // Adjust longitude
      });
    });

    return adjustedMarkers;
  };

  markerList = adjustMarkerPositions(markerList);
  const cameraCond = type === "home" ? markerList.length : markerList;
  const handleMapChange = ({ zoom, center, bounds }) => {
    boundsRef.current = bounds;
    // setBounds1(bounds);
    fetchMapDetails({ zoom, center });
  };

  useEffect(() => {
    if (boundsRef.current) {
      let data = [
        boundsRef.current.nw.lng,
        boundsRef.current.se.lat,
        boundsRef.current.se.lng,
        boundsRef.current.nw.lat,
      ];
      setBounds1(data); // Update bounds state once per change
    }
  }, [boundsRef.current]);
  const showStadiumName = (venue) => {
    if (map) {
      const zoomLvl = map.getZoom();
      return zoomLvl >= 12 ? (
        <div className="stadium-name">{venue ? venue : ""}</div>
      ) : null;
    }
  };
  const handleClickAttraction = (attraction) => {
    Mixpanel.track(`Pressed on Attraction From map`, {
      tittle: `${attraction.title}`,
      platform: platform,
      application_type: platform === "web" ? "web app" : "mobile app",
    });
    fetchAttractionRecomendationTypeData(attraction._id);
    setAttraction(attraction);
    setAttractionModal(true);
  };

  const flagUp = (fixture) => {
    if (width <= 320) {
      if (map) {
        const zoomLvl = map.getZoom();
        return zoomLvl >= 12 || isLeagues ? (
          <>
            <SelectedStadiumImage className={`selectedStadiumIcon`} />
            <div className="flag-images-container">
              <img
                className="flag-img"
                src={fixture?.home_team.logo}
                alt="logo"
                loading="lazy"
                onError={(e) => (e.target.src = fixture?.home_team.logo)}
              />
              <img
                className="flag-img"
                src={fixture?.away_team.logo}
                alt="logo"
                loading="lazy"
                onError={(e) => (e.target.src = fixture?.away_team.logo)}
              />
            </div>
          </>
        ) : (
          <img
            className="stadiumIcon"
            src={StadiumImageBlue}
            alt="marker"
            loading="lazy"
            onError={(e) => (e.target.src = StadiumImageBlue)}
          />
        );
      }
    } else {
      return (
        <>
          <SelectedStadiumImage className={`selectedStadiumIcon`} />
          <div className="flag-images-container">
            <img
              className="flag-img"
              src={fixture?.home_team.logo}
              alt="logo"
              loading="lazy"
              onError={(e) => (e.target.src = fixture?.home_team.logo)}
            />
            <img
              className="flag-img"
              src={fixture?.away_team.logo}
              alt="logo"
              loading="lazy"
              onError={(e) => (e.target.src = fixture?.away_team.logo)}
            />
          </div>
        </>
      );
    }
  };

  // useEffect(() => {
  //   if(!originalFixturesList){
  //     setCamCenter(searchedLocationCoords || userLocationCoords);
  //     googleCircle && map && map.fitBounds(googleCircle.getBounds(), 0);
  //   }
  // }, [originalFixturesList])

  useEffect(() => {
    console.log(center, "use effect", selectedMarker);
    if (center) {
      setCamCenter(center);
    } else {
      setCamCenter(coords);
      googleCircle && map && map.fitBounds(googleCircle.getBounds(), 0);
    }

    if (Array.isArray(selectedMarker)) {
      console.log(selectedMarker);
      const leaguesIds = new Set();

      selectedMarker.forEach((e) => {
        leaguesIds.add(e.league_id);
      });
      // Up flas if it's leagues
      if (leaguesIds.size === 1) {
        setLeague(true);
      } else {
        // down flags if's all leagues
        setLeague(false);
      }
      console.log({ userLocationCoords, searchedLocationCoords });
      if (insideRadiusFixtures.length) {
        const markersInRadius = [];
        selectedMarker.forEach((m) => {
          insideRadiusFixtures.forEach((rm) => {
            if (rm.fixture_id === m.fixture_id) {
              markersInRadius.push(m);
            }
          });
        });
        if (markersInRadius.length) {
          markersInRadius.push(coords);
          animateCamera(markersInRadius, map);
          map.setZoom(map.getZoom() - 1);
        } else {
          animateCamera(selectedMarker, map);
        }
      } else {
        animateCamera(selectedMarker, map);
      }
    } else {
      console.log("test =====>");
      if (selectedMarker === null) {
        setLeague(false);
      } else {
        setLeague(true);
      }

      animateCamera(selectedMarker, map);

      setCamCenter({
        lat: selectedMarker?.location.lat,
        lng: selectedMarker?.location.lng,
      });
      if (width >= 800) {
        if (
          markerSelectedOnHover?.fixture_id &&
          !context.matchesToday[markerSelectedOnHover?.fixture_id]
        ) {
          animateCamera(markerSelectedOnHover, map);
          setCamCenter({
            lat: markerSelectedOnHover.lat,
            lng: markerSelectedOnHover.lng,
          });
        } else {
          // setCamCenter(coords);
        }
      }
    }
  }, [selectedMarker, markerSelectedOnHover]);

  useEffect(() => {
    if (center) {
      setCamCenter(center);
    } else if (
      (insideRadiusFixtures.length || outsideRadiusFixtures.length) &&
      !searchBy
    ) {
      if (insideRadiusFixtures.length) {
        setCamCenter(insideRadiusFixtures[0]?.location);
        googleCircle && map && map.fitBounds(googleCircle.getBounds(), 0);
      } else if (outsideRadiusFixtures.length) {
        setCamCenter(outsideRadiusFixtures[0]?.location);
        googleCircle && map && map.fitBounds(googleCircle.getBounds(), 0);
      }
    } else {
      setCamCenter(coords);
      googleCircle && map && map.fitBounds(googleCircle.getBounds(), 0);
    }
  }, [center]);

  // useEffect(() => {
  //   if (map && googleCircle && type !== "live") {
  //     map.fitBounds(googleCircle.getBounds(), 0);
  //   }
  // }, [map, searchedLocationCoords, type]);

  // useEffect(() => {
  //   if (map && googleCircle) {
  //     if (
  //       insideRadiusFixtures &&
  //       insideRadiusFixtures.length &&
  //       type !== "live"
  //     ) {
  //       map.fitBounds(googleCircle.getBounds(), 0);
  //     }
  //   }
  // }, [map, radius, googleCircle, type]);

  // useEffect(() => {
  //   if (!liveMatch) {
  //     if (
  //       !isLiveKeepFetching &&
  //       type === "live" &&
  //       markerList?.length > 0 &&
  //       map
  //     ) {
  //       animateCamera(markerList, map);

  //       if (markerList.length === 1) {
  //         const zoom = map.getZoom();
  //         map.setZoom(zoom - 11);
  //       }
  //     }
  //   }
  // }, [isLiveKeepFetching, type, markerList, map]);

  // useEffect(() => {
  //   if (type === "home") {
  //     if (!insideRadiusFixtures.length) {
  //       animateCamera(markerList, map);
  //       if (map && markerList.length === 1) {
  //         map.setZoom(map.getZoom() - 15);
  //       }
  //     } else {
  //       if (map && googleCircle) {
  //         map.fitBounds(googleCircle.getBounds(), 0);
  //       }
  //     }
  //   }
  // }, [cameraCond, type, map, insideRadiusFixturesCond]);

  useEffect(() => {
    if (coords && coords.lat && type === "home") {
      circle(map, radius, searchBy, coords, fetchMapDetails, mapCircle);
    }
  }, [coords, fetchMapDetails, map, radius, searchBy, type]);

  // useEffect(() => {
  //   console.log(
  //     attractionMarkerSelectedOnHover,
  //     "-------------------------------------------"
  //   );
  // }, [attractionMarkerSelectedOnHover]);

  useEffect(() => {
    polyline(selectedMarker, supporters, areFixturesDetailsFetched, map);
  }, [supporters, areFixturesDetailsFetched]);

  const handleMarkerClick = (fixture, isMobile) => {
    Mixpanel.track(`Pressed on marker`, {
      match: `${fixture?.home_team?.name} vs ${fixture?.away_team?.name}`,
      platform: platform,
      application_type: platform === "web" ? "web app" : "mobile app",
      venue: "${venue?.name ?? venue}",
      latitude: `${fixture?.lat}`,
      longitude: `${fixture?.lng}`,
    });
    console.log("handleMarkerClick", selectedMarker, fixture);
    const { lat, lng, fixture_id } = fixture;

    if (type === "live") {
      onMarkerClick(fixture_id);
      if (!selectedMarker || selectedMarker.fixture_id !== fixture.fixture_id) {
        resetMatchDetails();
        resetLiveDetails();
      }

      openDetailsSection(true);
      fetchLiveMatch(fixture);
      var bounds = new window.google.maps.LatLngBounds();

      bounds.extend({ lat, lng });

      map.fitBounds(bounds);
      const zoom = map.getZoom();
      map.setZoom(zoom - 15);
    } else {
      if (!Array.isArray(selectedMarker)) {
        console.log("handleMarkerClick IFARRAY");
        context.setIsMarker(true);
        if (selectedMarker?.fixture_id !== fixture.fixture_id) {
          console.log("handleMarkerClick IFARRAY IFFF");
          resetMatchDetails();
          fetchFixture(fixture);
        }
      }
    }
    document.getElementById(`item-${fixture_id}`) &&
      document.getElementById(`item-${fixture_id}`).scrollIntoView();

    console.log("selectedMarker", selectedMarker, {
      zoom: type === "live" ? 6 : 12,
      center: { lat, lng },
      marker: fixture,
      isMobile: isMobile ? true : false,
    });

    if (isMobile) {
      if (selectedMarker?.fixture_id) {
        console.log("IF-IF");
        fetchMapDetails({
          zoom: type === "live" ? 6 : 12,
          center: { lat, lng },
          marker: fixture,
          isMobile: true,
        });
      } else {
        console.log("IF-ELSE");
        fetchMapDetails({
          zoom: type === "live" ? 6 : 12,
          center: { lat, lng },
          marker: fixture,
        });
      }
    } else {
      console.log("ELSE");
      fetchMapDetails({
        zoom: type === "live" ? 6 : 12,
        center: { lat, lng },
        marker: fixture,
      });
    }
  };

  const renderMarker = (markers, fixture) => {
    const { fixture_id, lat, lng, league_id } = fixture;

    if (Array.isArray(markers)) {
      if (!markers.length) return null;

      return markers.map((item) => {
        if (item?.league_id === league_id)
          return (
            <div>
              <SelectedStadium
                handleMouseLeave={() => {
                  if ("ontouchstart" in document.documentElement) {
                    return;
                  } else {
                    fetchMapDetails({ markerSelectedOnHover: null });
                  }
                }}
                handleMouseEnter={() => {
                  if ("ontouchstart" in document.documentElement) {
                    return;
                  } else {
                    if (type === "live")
                      fetchLiveMatchOnHover({ liveMatchOnHover: fixture });
                    else fetchMapDetails({ markerSelectedOnHover: fixture });
                  }
                }}
                callback={() => {
                  if ("ontouchstart" in document.documentElement) {
                    return;
                  } else {
                    handleMarkerClick(item);
                  }
                }}
                showStadiumName={showStadiumName}
                fixture={item}
                flagUp={flagUp}
                key={item.fixture_id}
                lat={item.lat}
                lng={item.lng}
                map
              />
            </div>
          );
      });
    } else {
      //  if(!markers ) return null;
      return (
        <SelectedStadium
          handleMouseLeave={() => {
            if ("ontouchstart" in document.documentElement) {
              return;
            } else {
              fetchMapDetails({ markerSelectedOnHover: null });
            }
          }}
          handleMouseEnter={() => {
            if ("ontouchstart" in document.documentElement) {
              return;
            } else {
              if (type === "live")
                fetchLiveMatchOnHover({ liveMatchOnHover: fixture });
              else fetchMapDetails({ markerSelectedOnHover: fixture });
            }
          }}
          callback={() => {
            if ("ontouchstart" in document.documentElement) {
              handleMarkerClick(fixture, true);
              // return;
            } else {
              handleMarkerClick(fixture);
            }
          }}
          showStadiumName={showStadiumName}
          flagUp={flagUp}
          fixture={fixture}
          key={fixture_id}
          lat={lat}
          lng={lng}
          map
        />
      );
    }
  };

  // const isMobile =
  //   /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phon /i.test(
  //     navigator.userAgent
  //   );

  const data = useMemo(() => {
    // let res = markerList;
    // let res = [];
    const res = markerList.map((elem) => {
      return {
        type: "Feature",
        properties: {
          cluster: false,
          category: "fixtures",
          fixture_id: elem.fixture_id,
        },
        geometry: {
          type: "Point",
          coordinates: [parseFloat(elem.lng), parseFloat(elem.lat)],
        },
      };
    });
    return res;
  }, [markerList]);
  const { clusters, supercluster } = useSupercluster({
    points: data,
    bounds: bounds1,
    zoom,
    options: clusteringOptions,
  });

  const [clusterContainingFixture, setClusterContainingFixture] =
    useState(null);

  useEffect(() => {
    if (markerSelectedOnHover && clusters) {
      const { lat, lng, fixture_id } = markerSelectedOnHover;

      const fixturePoint = [parseFloat(lng), parseFloat(lat)];
      let foundCluster = null;
      for (const cluster of clusters) {
        if (cluster.properties.cluster) {
          const leaves = supercluster.getLeaves(cluster.id);
          if (
            leaves.some((leaf) => leaf.properties.fixture_id === fixture_id)
          ) {
            foundCluster = cluster; // Return the cluster containing the match
            break;
          }
        } else {
          if (cluster.properties.fixture_id === fixture_id) {
            foundCluster = cluster; // Match found as a standalone point
            break;
          }
        }
      }
      // const foundCluster = clusters.find((cluster) => {
      //   if (cluster.properties.cluster) {
      //     console.log(cluster);
      //     const clusterCenter = cluster.geometry.coordinates;
      //     const distanceToCluster = distance(fixturePoint, clusterCenter);
      //     const thresholdDistance = 20;
      //     return distanceToCluster <= thresholdDistance;
      //   } else {
      //     return (
      //       cluster.geometry.coordinates[0] === fixturePoint[0] &&
      //       cluster.geometry.coordinates[1] === fixturePoint[1]
      //     );
      //   }
      // });
      setClusterContainingFixture(foundCluster);
    }
  }, [markerSelectedOnHover, clusters]);
  // useEffect(() => {
  //   console.log(supercluster);
  // }, [clusters, supercluster]);

  // console.log({ camCenter });
  return (
    <div className="map-top-con">
      {(isFetchingFixtures || isFetchingOnDate || !isMapLoaded ) && width > 1000 && (
        <div className="map-spinner">
          <Spinner />
        </div>
      )}
      <div
        className={`map-container ${
          (isFetchingFixtures || isFetchingOnDate) &&
          width > 1000 &&
          "map-overlay"
        }`}
      >
        {(isFetchingFixtures || isFetchingOnDate) && width < 1000 && (
          <Spinner />
        )}

        <GoogleMapReact
          // googleMapLoader={(e) => console.log({ e })}
          defaultCenter={
            userLocationCoords || { lat: 51.509865, lng: -0.118092 }
          }
          center={camCenter}
          zoom={zoom}
          bootstrapURLKeys={{
            key: "AIzaSyD2EUbN8GTw0joJ629a5RiZlauA7S65-8M",
            libraries: "places",
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            window.myCallbackFunc && window.myCallbackFunc();
            fetchMapDetails({ internalMap: map });
            map.setOptions({
              gestureHandling: "greedy", // 'greedy' allows for both zoom and pan with one finger
            });
            setIsMapLoaded(true);
          }}
          onTilesLoaded={() => setIsMapLoaded(true)}
          onChange={handleMapChange}
        >
          {clusters &&
            clusters.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                cluster.properties;

              if (isCluster) {
                let size = (pointCount * 20) / data.length;
                return (
                  <Marker
                    lat={latitude}
                    lng={longitude}
                    key={`cluster-${cluster.id}`}
                    // className="cluster-marker"
                    onClick={() => {
                      Mixpanel.track(`Pressed on cluster`, {
                        platform: platform,
                        application_type:
                          platform === "web" ? "web app" : "mobile app",
                        total_matches_in_cluster: pointCount,
                      });
                      // const expansionZoom = Math.min(
                      //   supercluster.getClusterExpansionZoom(cluster.id),
                      //   60
                      // );
                      // map.setZoom(expansionZoom);
                      // map.panTo({ lat: latitude, lng: longitude });
                      const leaves = supercluster.getLeaves(cluster.id);
                      if (leaves.length > 0) {
                        const bounds = new window.google.maps.LatLngBounds(); // Initialize new LatLngBounds

                        leaves.forEach((leaf) => {
                          const [lng, lat] = leaf.geometry.coordinates; // Get the coordinates of each leaf
                          bounds.extend(
                            new window.google.maps.LatLng(lat, lng)
                          ); // Extend the bounds with the marker's position
                        });

                        // Fit the map to the bounds
                        if (map) {
                          map.fitBounds(bounds);
                        }
                      }
                    }}
                    isSelected={
                      clusterContainingFixture &&
                      (clusterContainingFixture.id === cluster.id ||
                        cluster.properties.fixture_id === cluster.id)
                    }
                    markerSelectedOnHover={markerSelectedOnHover}
                    count={pointCount}
                  ></Marker>
                );
              } else {
                const fixture_id = cluster.properties.fixture_id;
                const fixture = markerList.find(
                  (marker) => marker.fixture_id === fixture_id
                );

                if (!fixture) return null;
                const {
                  lat,
                  lng,
                  statusShort,
                  home_team,
                  away_team,
                  venue,
                  elapsed,
                  goalsAwayTeam,
                  goalsHomeTeam,
                } = fixture;
                if (!fixture.distance) return;
                return (type === "home" &&
                  !markerSelectedOnHover &&
                  !selectedMarker?.league_id &&
                  !selectedMarker?.length) ||
                  (type === "live" &&
                    liveMatch &&
                    liveMatch?.fixture_id === fixture_id) ||
                  (type === "live" &&
                    liveMatchOnHover &&
                    liveMatchOnHover?.fixture_id === fixture_id) ||
                  onlyLiveMatches ||
                  showOnlyFav ? (
                  type === "live" ? (
                    <LiveMarker
                      className="live-marker"
                      handleMouseLeave={() => {
                        if ("ontouchstart" in document.documentElement) {
                          return;
                        } else {
                          fetchLiveMatchOnHover({ liveMatchOnHover: null });
                        }
                      }}
                      callback={() => {
                        if ("ontouchstart" in document.documentElement) {
                          return;
                        } else {
                          handleMarkerClick(fixture);
                        }
                      }}
                      status={statusShort}
                      key={fixture_id}
                      lat={lat}
                      lng={lng}
                      homeTeam={home_team?.logo}
                      awayTeam={away_team?.logo}
                      score={
                        statusShort !== "NS" && statusShort !== "TBD"
                          ? `${goalsHomeTeam} : ${goalsAwayTeam}`
                          : "- : -"
                      }
                      showStadiumName={showStadiumName}
                      venue={venue}
                      matchTime={elapsed}
                    />
                  ) : (
                    renderMarker(selectedMarker, fixture, map)
                  )
                ) : liveMatchOnHover ||
                  markerSelectedOnHover?.fixture_id === fixture_id ||
                  selectedMarker?.id === fixture?.id ||
                  selectedMarker?.length ? (
                  renderMarker(selectedMarker, fixture, map)
                ) : (
                  <StadiumBlue
                    fixture={fixture}
                    handleMouseEnter={() => {
                      console.log("hand fixture", fixture);
                      if ("ontouchstart" in document.documentElement) {
                        return;
                      } else {
                        if (type === "live")
                          fetchLiveMatchOnHover({ liveMatchOnHover: fixture });
                        else {
                          fetchMapDetails({
                            marker: null,
                            markerSelectedOnHover: fixture,
                            center: {
                              lat: fixture.lat,
                              lng: fixture.lng,
                            },
                          });
                        }
                      }
                    }}
                    handleMouseLeave={() => {
                      if ("ontouchstart" in document.documentElement) {
                        return;
                      } else {
                        fetchLiveMatchOnHover({ liveMatchOnHover: null });
                      }
                    }}
                    callback={() => {
                      if ("ontouchstart" in document.documentElement) {
                        handleMarkerClick(fixture);
                      }
                    }}
                    showStadiumName={showStadiumName}
                    key={fixture_id}
                    lat={lat}
                    lng={lng}
                  />
                );
              }
            })}
          {supporters &&
            selectedMarker &&
            supporters.map(({ _id, location, type }) =>
              type === "pub" ? (
                <Pub
                  logoUrl={selectedMarker?.home_team?.logo}
                  key={_id}
                  supporterLocation={{ lat: location.lat, lng: location.lng }}
                  stadiumLocation={{
                    lat: selectedMarker.lat,
                    lng: selectedMarker.lng,
                  }}
                  lat={location.lat}
                  lng={location.lng}
                />
              ) : type === "Hotel" ? (
                <Hotel key={_id} lat={location?.lat} lng={location?.lng} />
              ) : (
                <Restaurant key={_id} lat={location?.lat} lng={location?.lng} />
              )
            )}
          {attractions &&
            selectedMarker &&
            attractions.map((attraction) => {
              const { _id, location, attraction_type, title } = attraction;

              return attraction_type.type_name === "pubs" ? (
                <Pub
                  logoUrl={selectedMarker?.home_team?.logo}
                  key={_id}
                  supporterLocation={{
                    lat: location?.coordinates[1],
                    lng: location?.coordinates[0],
                  }}
                  stadiumLocation={{
                    lat: selectedMarker.lat,
                    lng: selectedMarker.lng,
                  }}
                  name={title}
                  onClick={() => handleClickAttraction(attraction)}
                  onMouseEnter={() => {
                    fetchMapDetailsOnAttraction({
                      attractionMarkerSelectedOnHover: attraction,
                    });
                  }}
                  onMouseLeave={() => {
                    fetchMapDetailsOnAttraction({
                      attractionMarkerSelectedOnHover: null,
                    });
                  }}
                  lat={location?.coordinates[1]}
                  lng={location?.coordinates[0]}
                  isSelectedAtraction={
                    attractionMarkerSelectedOnHover &&
                    attractionMarkerSelectedOnHover?._id === _id
                      ? true
                      : false
                  }
                />
              ) : attraction_type.type_name === "Hotel" ? (
                <Hotel
                  key={_id}
                  onClick={() => handleClickAttraction(attraction)}
                  lat={location?.coordinates[1]}
                  lng={location?.coordinates[0]}
                  name={title}
                  isSelectedAtraction={
                    attractionMarkerSelectedOnHover &&
                    attractionMarkerSelectedOnHover?._id === _id
                      ? true
                      : false
                  }
                  onMouseEnter={() => {
                    fetchMapDetailsOnAttraction({
                      attractionMarkerSelectedOnHover: attraction,
                    });
                  }}
                  onMouseLeave={() => {
                    fetchMapDetailsOnAttraction({
                      attractionMarkerSelectedOnHover: null,
                    });
                  }}
                />
              ) : (
                <Restaurant
                  key={_id}
                  onClick={() => handleClickAttraction(attraction)}
                  lat={location?.coordinates[1]}
                  lng={location?.coordinates[0]}
                  name={title}
                  isSelectedAtraction={
                    attractionMarkerSelectedOnHover &&
                    attractionMarkerSelectedOnHover?._id === _id
                      ? true
                      : false
                  }
                  onMouseEnter={() => {
                    fetchMapDetailsOnAttraction({
                      attractionMarkerSelectedOnHover: attraction,
                    });
                  }}
                  onMouseLeave={() => {
                    fetchMapDetailsOnAttraction({
                      attractionMarkerSelectedOnHover: null,
                    });
                  }}
                />
              );
            })}

          {coords && coords.lat && (
            <UserLocation lat={coords.lat} lng={coords.lng} />
          )}
          {/* <img
            src={require("../../assets/images/powered_by_google_on_white.png")}
            alt="google-attribution"
            className="google-attr"
          /> */}
          {/* <div className="google-atr"><b>GOOO ATRIBUTE</b></div> */}
        </GoogleMapReact>
        {!isWishlistFrom && <ShowAllMatches itemRef={itemRef} route={type} />}
        <IntroMessage />
        {type === "live" ? null : <MobileSearchBar />}

        {(type === "home" && searchBy !== "leagues") || type !== "live" ? (
          <MobileSearchDetails />
        ) : null}
      </div>
      {isMobile ? (
        <MapControls setFilterModal={setFilterModal} coords={coords} />
      ) : null}

      <SupporterInfoButton route={type} />
      <HelpSupporterBtn />
      {attractionModal && (
        <AttractionModal
          setAttractionModal={setAttractionModal}
          attractionModal={attractionModal}
          attraction={attraction}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  originalFixturesList: selectFixturesList(state),
  markerSelected: selectMarkerSelected(state),
  searchedLocationCoords: selectFormValuesSearchedLocation(state),
  userLocationCoords: selectUserLocationCoords(state),
  radius: selectFormValuesRadius(state),
  selectedMarker: selectMarker(state),
  zoom: selectZoom(state),
  center: selectMapCenter(state),
  map: selectInternalMap(state),
  searchBy: selectSearchBy(state),
  markerSelectedOnHover: selectMarkerSelectedOnHover(state),
  mapCircle: selectCircle(state),
  supporters: selectSupporters(state),
  areFixturesDetailsFetched: selectAreFixturesDetailsFetched(state),
  width: selectWidth(state),
  isFetchingFixtures: selectFixturesFetching(state),
  liveMatch: selectLiveMatch(state),
  liveMatchOnHover: selectLiveMatchOnHover(state),
  showOnlyFav: selectShowOnlyFav(state),
  favMatchesIds: selectFavMatches(state),
  isLiveKeepFetching: selectIsLiveKeepFetching(state),
  onlyLiveMatches: selectShowOnlyLive(state),
  attractions: selectAttractions(state),
  attractionMarkerSelectedOnHover: selectAttractionMarkerSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchFixture: (payload) => dispatch(fetchFixture(payload)),
  fetchLiveMatch: (payload) => dispatch(fetchLiveMatch(payload)),
  fetchLiveMatchOnHover: (payload) => dispatch(fetchLiveMatchOnHover(payload)),
  resetMatchDetails: () => dispatch(resetMatchDetails()),
  resetLiveDetails: () => dispatch(resetLiveDetails()),
  fetchMapDetailsOnAttraction: (data) =>
    dispatch(fetchMapDetailsOnAttraction(data)),
  openDetailsSection: (payload) => dispatch(openDetailsSection(payload)),
  fetchAttractionRecomendationTypeData: (payload) =>
    dispatch(fetchAttractionRecomendationTypeData(payload)),
});

StadiumBlue.propTypes = {
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  callback: PropTypes.func,
  fixture: PropTypes.object,
  showStadiumName: PropTypes.func,
};

Pub.propTypes = {
  logoUrl: PropTypes.string,
  stadiumLocation: PropTypes.object,
  supporterLocation: PropTypes.object,
};

Stadium.propTypes = {
  fixture: PropTypes.object,
  showStadiumName: PropTypes.func,
  callback: PropTypes.func,
  handleMouseEnter: PropTypes.func,
};

SelectedStadium.propTypes = {
  callback: PropTypes.func,
  fixture: PropTypes.object,
  handleMouseLeave: PropTypes.func,
  showStadiumName: PropTypes.func,
};

LiveMarker.propTypes = {
  callback: PropTypes.func,
  handleMouseLeave: PropTypes.func,
};

Maps.propTypes = {
  originalFixturesList: PropTypes.array,
  userLocationCoords: PropTypes.object,
  searchedLocationCoords: PropTypes.object,
  radius: PropTypes.number,
  selectedMarker: PropTypes.object,
  Maps: PropTypes.array,
  zoom: PropTypes.number,
  center: PropTypes.any,
  fetchMapDetails: PropTypes.func,
  map: PropTypes.object,
  searchBy: PropTypes.string,
  type: PropTypes.string,
  favMatchesIds: PropTypes.array,
  onlyLiveMatches: PropTypes.bool,
  isWishlistFrom: PropTypes.bool,
  showOnlyFav: PropTypes.bool,
  markerSelectedOnHover: PropTypes.object,
  mapCircle: PropTypes.object,
  supporters: PropTypes.array,
  areFixturesDetailsFetched: PropTypes.bool,
  width: PropTypes.number,
  fetchFixture: PropTypes.func,
  isFetchingFixtures: PropTypes.bool,
  liveMatch: PropTypes.object,
  liveMatchOnHover: PropTypes.object,
  fetchLiveMatch: PropTypes.func,
  fetchLiveMatchOnHover: PropTypes.func,
  resetMatchDetails: PropTypes.func,
  resetLiveDetails: PropTypes.func,
  openDetailsSection: PropTypes.func,
  isLiveKeepFetching: PropTypes.bool,
  itemRef: PropTypes.object,
  insideRadiusFixtures: PropTypes.array,
  outsideRadiusFixtures: PropTypes.array,
  onMarkerClick: PropTypes.func,
  setFilterModal: PropTypes.func,
  fetchMapDetailsOnAttraction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Maps);
